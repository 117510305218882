// import styled from "styled-components";
import styled from "styled-components";
import DetailTop from "./components/DetailTop";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import DetailLocation from "./components/DetailLocation";
import DetailPoint from "./components/DetailPoint";
import DetailInformation from "./components/DetailInformation";
import DetailOptions from "./components/DetailOptions";
import DetailPrice from "./components/DetailPrice";
import DetailMansionExplain from "./components/DetailMansionExplain";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../services/axiosConfig";
import { propertyOptionDataType } from "../../recoil/mansionPage/propertyOptionsAtom";
import arrowIcon from "../../assets/images/mainPage/arrow-gray.png";
import shareIcon from "../../assets/images/detail/share.png";
import scrapIcon from "../../assets/images/detail/scrap.png";
import {Helmet} from "react-helmet";

export type roomDetailType = {
  id: number;
  detailCategory: {
    id: number;
    name: string;
  };
  name: string;
  roomNum: number;
  thumbnailUrl: string;
  floorPlanUrl: string;
  roomImgUrlList: string[];
  zipCode: string;
  address: string;
  additionalAddress: string;
  stationInfo: string[];
  latitude: number;
  longitude: number;
  recommendPoints: string[];
  roomType: string;
  area: number;
  numOfFloor: number;
  totalFloor: number;
  contractTerm: number;
  structure: "woodcarving" | "rebar" | "ironFrame" | "other" | "non";
  penaltyOption: string;
  penaltyAmount: string;
  direction: string;
  option: propertyOptionDataType;
  rent: number;
  managementFee: number;
  moreFixedCost: string;
  deposit: number;
  gratuity: number;
  keyExchangeFee: number;
  fireInsuranceCost: number;
  guaranteeReviewFee: number;
  brokerageFee: number;
  moreInitialCost: string;
  description: string;
  moreInfo: string;
  scrapbook: boolean;
  constructedDate: string;
};

function Detail() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const roomId = queryParams.get("roomId");
  const [roomDetailState, setRoomDetailState] = useState<roomDetailType>();

  const [scrapState, setScrapState] = useState<boolean>(false);

  const onClickScrap = async (e: React.MouseEvent) => {
    const token = window.sessionStorage.getItem("Authorization");

    e.stopPropagation();

    if (token) {
      console.log(scrapState);

      if (!scrapState) {
        try {
          const response = await axiosInstance.post(`/normal/user/scrap`, {
            category: "room",
            entityId: roomId,
          });
          if (response.status === 200) {
            alert("스크랩 되었습니다.");
          }
        } catch (error) {
          alert("스크랩에 실패했습니다");
          console.log(error);
        }
        setScrapState(true);
      } else {
        //마이 페이지 스크랩 페이지로 이동
        navigate("/mypage/scrab/all");
      }
    } else {
      alert("로그인 후 이용할 수 있는 서비스 입니다.");
      navigate("/login");
    }
  };

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const response: { data: roomDetailType } = await axiosInstance.get(
          `property/${roomId}`
        );

        console.log(response.data);

        setRoomDetailState(response.data);
        console.log(response.data.scrapbook);

        setScrapState(response.data.scrapbook);
      } catch (error) {
        console.log(error);
        alert("존재하지 않는 호실이거나 호실 조회에 실패했습니다.");

        navigate(-1);
      }
    };
    fetchDetail();
  }, []);

  return (
      <>
        <Helmet>
          <title>Houber - detail</title>
          <meta name="robots" content="index,follow"/>
          <meta name="description" content={`Houber에서 제공하는 ${roomId}의 상세 정보입니다.`}/>
          <meta name="keywords" content="일본, 일본 워홀, 워킹홀리데이,일본 임대,일본 매매,일본 점포,일본 맨션, property, room"/>
          <meta property="og:title" content={`Houber - 매물 상세 ${roomId}`}/>
          <meta property="og:description" content={`Houber에서 제공하는 ${roomId}의 상세 정보입니다.`}/>
          <link rel="canonical" href={`https://houber-home.com/detail?roomId=${roomId}`}/>
        </Helmet>
        <ResponsiveWrapper>
          <RemoteControllerWrapper>
            <RemoteController>
          <StyledImage
            src={shareIcon}
            onClick={() => {
              navigator.clipboard.writeText(
                `https://www.houber-home.com/detail?roomId=${roomId}`
              );
              alert(
                `링크가 클립보드에 복사되었습니다. https://www.houber-home.com/detail?roomId=${roomId}`
              );
            }}
          ></StyledImage>
          <StyledImage
            src={scrapIcon}
            onClick={(e) => {
              onClickScrap(e);
            }}
          ></StyledImage>
        </RemoteController>
      </RemoteControllerWrapper>
      <BackButton onClick={() => navigate(-1)}>
        <BackIcon src={arrowIcon}></BackIcon>
        {"뒤로가기"}
      </BackButton>
      <DetailLayout>
        <DetailContentsWrapper>
          <DetailTop roomDetailState={roomDetailState} />
          <DetailLocation roomDetailState={roomDetailState} />
          <DetailPoint roomDetailState={roomDetailState} />
          <DetailInformation roomDetailState={roomDetailState} />
          <DetailOptions roomDetailState={roomDetailState} />
          <DetailPrice roomDetailState={roomDetailState} />
          <DetailMansionExplain roomDetailState={roomDetailState} />
        </DetailContentsWrapper>
      </DetailLayout>
    </ResponsiveWrapper>
        </>
  );
}

export default Detail;

const BackIcon = styled.img`
  transform: rotate(180deg);
  @media screen and (min-width: 1280px) {
    width: 12px;
    height: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 9px;
    height: 16px;
  }
  @media screen and (max-width: 1023px) {
    width: 5px;
    height: 9px;
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  font-weight: 500;
  @media screen and (min-width: 1280px) {
    font-size: 23px;
    margin-top: 89px;
    gap: 5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
    margin-top: 87px;
    gap: 4px;
  }
  @media screen and (max-width: 1023px) {
    position: relative;
    font-size: 10px;
    margin-top: 29px;
    gap: 2px;
  }
`;

const DetailLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const DetailContentsWrapper = styled.div`
  @media screen and (min-width: 1280px) {
    width: 700.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 655px;
  }
  @media screen and (max-width: 1023px) {
    width: 385px;
  }
`;

const RemoteControllerWrapper = styled.div`
  position: fixed;
  height: 70vh;
  z-index: 1000;
  pointer-events: none;
  @media screen and (min-width: 1280px) {
    width: 1160px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 934px;
  }
  @media screen and (max-width: 1023px) {
    width: 385px;
  }
`;

const RemoteController = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 10%;
  pointer-events: auto;
  @media screen and (min-width: 1280px) {
    right: 60px;
    gap: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    right: 45px;
    gap: 19px;
  }
  @media screen and (max-width: 1023px) {
    right: 0;
    gap: 10px;
  }
`;

const StyledImage = styled.img`
  cursor: pointer;

  @media screen and (min-width: 1280px) {
    width: 54.5px;
    height: 54.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 51px;
    height: 51px;
  }
  @media screen and (max-width: 1023px) {
    width: 28.5px;
    height: 28.5px;
  }
`;
