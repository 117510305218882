import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import RoomItem from "./RoomItem";
import { mansionDataType } from "../Mansion";

function MansionItem({ mansionInfo }: { mansionInfo: mansionDataType }) {
  const { isMobile } = useDeviceSize();

  return (
    <MansionItemWrapper>
      <MansionInformation>
        <InformaionImageDiv>
          <StyledImage
            src={mansionInfo.thumbnailUrl}
            alt="thumbnail"
          ></StyledImage>
        </InformaionImageDiv>
        <InformationTextDiv>
          <MansionName>{mansionInfo.name}</MansionName>
          <MansionDetailDiv>
            <MansionDetail>주소: {mansionInfo.address}</MansionDetail>
            <MansionDetail>
              교통:{" "}
              {mansionInfo.stationInfo &&
                mansionInfo.stationInfo.map((item, index) => item).join(", ")}
            </MansionDetail>
            <MansionDetail>건축: {mansionInfo.constructedDate}</MansionDetail>
          </MansionDetailDiv>
        </InformationTextDiv>
      </MansionInformation>
      {!isMobile && (
        <TabeleNameDiv>
          <FloorPlanTableName>도면</FloorPlanTableName>
          <ExtraTableNameDiv>
            <ExtraTableName>
              <div>호실</div>
              <div>층수</div>
            </ExtraTableName>
            <ExtraTableName>
              <div>방타입</div>
              <div>면적</div>
            </ExtraTableName>
            <ExtraTableName>
              <div>월세</div>
              <div>관리비</div>
            </ExtraTableName>
            <ExtraTableName>
              <div>시키킹</div>
              <div>레이킹</div>
            </ExtraTableName>
          </ExtraTableNameDiv>
        </TabeleNameDiv>
      )}
      <RoomItemDiv>
        {mansionInfo.searchedRoom.map((item, index) => (
          <RoomItem roomInfo={item}></RoomItem>
        ))}
      </RoomItemDiv>
    </MansionItemWrapper>
  );
}

export default MansionItem;

const MansionItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 1280px) {
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  }
  @media screen and (max-width: 1023px) {
  }
`;

const MansionInformation = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: #f5f5f5;
  @media screen and (min-width: 1280px) {
    height: 226px;
    border-radius: 10px;
    padding: 11px 13px;
    gap: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 190px;
    border-radius: 10px;
    padding: 10px 11px;
    gap: 17px;
  }
  @media screen and (max-width: 1023px) {
    height: 114px;
    border-radius: 5px;
    padding: 6px 6px;
    gap: 11px;
  }
`;

const InformaionImageDiv = styled.div`
  border: 1px solid #eeeeee;
  background-color: white;
  @media screen and (min-width: 1280px) {
    width: 270px;
    height: 203px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 226px;
    height: 170px;
  }
  @media screen and (max-width: 1023px) {
    width: 135px;
    height: 101.5px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const InformationTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;

const MansionName = styled.div`
  font-weight: 600;
  @media screen and (min-width: 1280px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 12px;
    font-weight: bold;
  }
`;
const MansionDetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    gap: 15px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 12px;
  }
  @media screen and (max-width: 1023px) {
    gap: 5px;
  }
`;
const MansionDetail = styled.div`
  line-height: 1.4;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 10px;
  }
`;

const TabeleNameDiv = styled.div`
  display: flex;
  border-bottom: 2px solid #eeeeee;
  font-weight: 500;
  @media screen and (min-width: 1280px) {
    margin-top: 13px;
    height: 81px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 7px;
    height: 72px;
  }
`;

const FloorPlanTableName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media screen and (min-width: 1280px) {
    margin-left: 11px;
    width: 140px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-left: 9px;
    width: 117.5px;
  }
`;

const ExtraTableNameDiv = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex: 1;
  @media screen and (min-width: 1280px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-left: 25px;
  }
`;

const ExtraTableName = styled.div`
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    gap: 11px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 10px;
  }
`;

const RoomItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    margin-top: 20px;
    gap: 32px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 17px;
    gap: 27px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 13px;
    gap: 11px;
  }
`;
