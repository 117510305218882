import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import axiosInstance from "../../services/axiosConfig";
import checkRegion from '../../assets/images/checkRegion.png';


const mediaQueries = {
  tablet: "@media screen and (max-width: 1279px) and (min-width: 1024px)",
  mobile: "@media screen and (max-width: 1023px)",
};

const Header = styled.div`
  width: 100%;
  font-size: 16px;
  margin: 55px 0px;
  margin-left: 14px;
  text-align: left;
  color: #777777;
  ${mediaQueries.tablet} {
    font-size: 13px;
  }
  ${mediaQueries.mobile} {
    display: none;
  }
`;

const Con = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 735px;
  height: 100%;
  box-sizing: border-box;

  ${mediaQueries.tablet} {
  }
  ${mediaQueries.mobile} {
    width: 385px;
    margin-top: 21px;
  }
`;

const Section = styled.section`
  margin-bottom: 18px;
  border: 1.5px solid #cdcdcd;
  border-radius: 10px;
  padding: 37px 29px;
  width: 100%;
  box-sizing: border-box;

  ${mediaQueries.tablet} {
    padding: 37px 29px;
  }
  ${mediaQueries.mobile} {
    padding: 21px 21px;
    margin-bottom: 14px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 23px;
  font-weight: 600;
  margin-top: 55px;
  margin-bottom: 21px;
  color: #333333;
  width: 100%;
  align-items: center;

  ${mediaQueries.tablet} {
    font-size: 22px;
  }
  ${mediaQueries.mobile} {
    font-size: 14px;
    margin-top: 45px;
    margin-bottom: 10px;
    padding-left: 2px;
  font-weight: 700;

  }
`;

const Title2 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 23px;
  font-weight: bold;
  margin-top: 55px;
  margin-bottom: 13px;
  color: #333333;
  width: 100%;
  font-weight: 600;

  ${mediaQueries.tablet} {
    font-size: 22px;
  }
  ${mediaQueries.mobile} {
    font-size: 14px;
    padding-left: 2px;
  font-weight: 700;

  }
`;

const Note = styled.p`
  font-size: 19px;
  color: #111;
  margin-bottom: 16px;
  line-height: 1.5;
  letter-spacing: -0.040em;
  font-weight: 500;

  ${mediaQueries.tablet} {
    font-size: 19px;
  }
  ${mediaQueries.mobile} {
    display: none;
  }
`;

const MobileNote2 = styled.p`
  display: none;
  font-weight: 500;
  letter-spacing: -0.040em;



  ${mediaQueries.mobile} {
    display: block;
    font-size: 12px;
    color: #111;
    margin-bottom: 14px;
    line-height: 1.5;
    letter-spacing: -0.8px;
  }
`;

const Note2 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #777777;
  font-size: 16px;
  margin-bottom: 20px;
  width: 100%;
  ${mediaQueries.tablet} {
    font-size: 15px;
  }
  ${mediaQueries.mobile} {
    display: none;
  }
`;

// MobileNote 컴포넌트 스타일 정의
const MobileNote = styled.div`
  display: none;

  @media screen and (max-width: 1023px) {
    display: block;

    line-height: 1.5;

    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: #777777;
    font-size: 16px;
    margin-bottom: 8px;
    width: 100%;

    font-size: 12px;
    padding-left: 2px;
  }
`;

const P = styled.div`
  font-size: 23px;
  font-weight: bold;
  margin-right: 22px;
  color: #99393a;
  font-weight: 600;

  ${mediaQueries.tablet} {
    font-size: 22px;
  }
  ${mediaQueries.mobile} {
    font-size: 15px;
    margin-right: 7px;
  font-weight: 700;

  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 1.5px solid #cdcdcd;
  border-radius: 10px;
  padding: 24px 32px;
  width: 100%;
  box-sizing: border-box;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  ${mediaQueries.tablet} {
  }
  ${mediaQueries.mobile} {
    padding: 24px 17px;

    & > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: 400;

  font-size: 19px;
  color: #333;
  cursor: pointer;

  width: 100%;

  ${mediaQueries.tablet} {
    font-size: 19px;
  }
  ${mediaQueries.mobile} {
    font-size: 14px;
    padding-left: 2px;
  }
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  background-color: #fff;
  border: 1.5px solid #ddd;
  border-radius: 10px;
  width: 29px;
  height: 29px;
  margin-right: 16px;
  cursor: pointer;
  position: relative;

  &:checked {
    background-image: url(${checkRegion});
    background-size: contain; /* Ensures the image fits within the checkbox */
    background-repeat: no-repeat; /* Prevents repeating */
    background-position: center; /* Centers the image */
    border: none;
  }

  &:checked::after {
    content: '';
    position: absolute;
  }

  ${mediaQueries.tablet} {
  }

  ${mediaQueries.mobile} {
    margin-right: 7px;
    border-radius: 5px;

    width: 15px;
    height: 15px;

    &:checked::after {
      background-image: url(${checkRegion});
      background-size: contain; /* Ensures the image fits within the checkbox */
      background-repeat: no-repeat; /* Prevents repeating */
      background-position: center; /* Centers the image */
      background-size: 100% 100%;
      border: solid #ffffff;
      border-width: 0 1px 1px 0;
      
    }
  }
`;

const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 1.5px solid #cdcdcd;
  border-radius: 10px;
  padding: 24px 32px;
  width: 100%;
  box-sizing: border-box;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
  ${mediaQueries.tablet} {
    font-size: 19px;
  }
  ${mediaQueries.mobile} {
    padding: 15px 17px;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  min-height: 175px;
  font-size: 19px;
  box-sizing: border-box;
  color: #000000;
  border: 0px;
  resize: none;
outline: none;
font-family: 'Noto Sans', sans-serif;
font-weight: 500;

  &::placeholder {
    color: #bbbbbb; /* 원하는 색상으로 변경 */
  }

  ${mediaQueries.tablet} {
    font-size: 19px;
  }
  ${mediaQueries.mobile} {
    font-size: 13px;
    min-height: 85px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: 106px 0;

  ${mediaQueries.tablet} {
    margin: 106px 0;
  }
  ${mediaQueries.mobile} {
    margin: 44px 0;
    justify-content: space-between;
  }
`;

const Button = styled.button`
white-space: nowrap;
  padding: 17px 117px;
  font-size: 23px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
margin: 0 5.5px;

  &:first-child {
    background-color: white;
    color: #99393a;
    border: 1px solid #99393a;
  }

  &:last-child {
    background-color: #99393a;
    color: white;
  }

  ${mediaQueries.tablet} {
    font-size: 22px;
    padding: 15px 115px;
  }
  ${mediaQueries.mobile} {
    font-size: 13px;
    padding: 10px 70px;
margin: 0px;

  }
`;

const MobileHeader = styled.div`
  display: none;

  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Header1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 37px 0 21px 0;
  font-size: 20px;
  width: 100%;
  text-align: left;
  font-weight: 700;
`;

const Header2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 19px 0;
  font-size: 14px;
  width: 100%;
  text-align: left;
  font-weight: 500;
`;

const Line = styled.div`
  display: none;

  ${mediaQueries.mobile} {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }
`;

const WithdrawalPage: React.FC = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [reasons, setReasons] = useState<string[]>([]);
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get("/mypage/viewMyHome");

        setUserId(response.data.userId);
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (reasons.includes(value)) {
      setReasons(reasons.filter((reason) => reason !== value));
    } else {
      setReasons([...reasons, value]);
    }
  };

  const handleWithdraw = async () => {
    if (!isChecked) {
      alert("모든 내용을 확인하고 동의해야 합니다.");
      return;
    }
    if (reasons.length === 0) {
      alert("탈퇴 이유를 하나 이상 선택해야 합니다.");
      return;
    }
  
  
    try {
      await axiosInstance.delete("/normal/user/delAccount", {
        data: {
          userId: userId,
          agreementToDelete: isChecked,
          reasons: Array.from(
            new Set(
              reasons.map((reason) => {
                switch (reason) {
                  case "재가입":
                    return "REJOIN";
                  case "이용빈도 낮음":
                    return "LOW_USAGE";
                  case "혜택 부족":
                    return "LACK_OF_BENEFITS";
                  case "콘텐츠 및 상품 부족":
                    return "LACK_OF_CONTENT";
                  case "기타":
                    return "OTHER";
                  default:
                    return "OTHER";
                }
              })
            )
          ),
          suggestions: feedback,
        },
      });
  
      alert("회원 탈퇴가 완료되었습니다.");
      navigate("/");
    } catch (error) {
      // console.error("Error deleting account:", error);
      alert("회원 탈퇴 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  
  
  const handleCancel = () => {
    navigate("/mypage/home");
  };

  return (
    <>
      <ResponsiveWrapper>
        <Header>마이페이지 &gt; 설정하기 &gt; 탈퇴하기</Header>
        <MobileHeader>
          <Header1>마이페이지</Header1>
          <Header2>마이페이지 &gt; 설정하기 &gt; 탈퇴하기</Header2>
        </MobileHeader>
      </ResponsiveWrapper>
      <Line />
      <ResponsiveWrapper>
        <Con>
          <Box>
            <Section>
              <Note>* 구매정보 및 쿠폰은 소멸되며 복구가 불가능합니다</Note>
              <Note>
                * 소비자보호에 관한 법률 제6조에 의거, 계약 또는 청약철회 등에
                관한 기록은 5년, 대금결제 <br />및 재화 등의 공급에 관한 기록은
                5년, 소비자의 불만 또는 분쟁처리에 관한 기록은 3년 동안
                <br /> 보관됩니다. 개인정보는 법률에 의한 보유 목적 외에 다른
                목적으로는 이용되지 않습니다.
              </Note>
              <Note>
                * 회원 탈퇴 후 작성한 게시물은 삭제되지 않으며, 탈퇴 시 본인
                확인이 불가하므로 게시물
                <br /> 편집 및 삭제 처리가 불가능합니다.
              </Note>
              <MobileNote2>
                * 구매정보 및 쿠폰은 소멸되며 복구가 불가능합니다
              </MobileNote2>
              <MobileNote2>
                * 소비자보호에 관한 법률 제6조에 의거, 계약 또는 청약철회 등에
                관한
                <br /> 기록은 5년, 대금결제 및 재화 등의 공급에 관한 기록은 5년,
                소비자의 불만
                <br /> 또는 분쟁처리에 관한 기록은 3년 동안 보관됩니다.
                <br />
                개인정보는 법률에 의한 보유 목적 외에 다른 목적으로는 이용되지
                않습니다.
              </MobileNote2>
              <MobileNote2>
                * 회원 탈퇴 후 작성한 게시물은 삭제되지 않으며,
                <br /> 탈퇴 시 본인 확인이 불가하므로 게시물 편집 및 삭제 처리가
                불가능합니다.
              </MobileNote2>
            </Section>
            <CheckboxLabel style={{color:'#777777'}}>
              <P>필수</P>
              <Checkbox
                type="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              위 내용을 모두 확인하였으며 이에 동의합니다.
            </CheckboxLabel>

            <Title>
              <P>필수</P>
              탈퇴 이유 (복수선택 가능)
            </Title>
            <CheckboxContainer>
              <CheckboxLabel>
                <Checkbox
                  value="재가입"
                  checked={reasons.includes("재가입")}
                  onChange={handleCheckboxChange}
                />
                재가입
              </CheckboxLabel>
              <CheckboxLabel>
                <Checkbox
                  value="이용빈도 낮음"
                  checked={reasons.includes("이용빈도 낮음")}
                  onChange={handleCheckboxChange}
                />
                이용빈도 낮음
              </CheckboxLabel>
              <CheckboxLabel>
                <Checkbox
                  value="혜택 부족"
                  checked={reasons.includes("혜택 부족")}
                  onChange={handleCheckboxChange}
                />
                혜택 부족
              </CheckboxLabel>
              <CheckboxLabel>
                <Checkbox
                  value="콘텐츠 및 상품 부족"
                  checked={reasons.includes("콘텐츠 및 상품 부족")}
                  onChange={handleCheckboxChange}
                />
                콘텐츠 및 상품 부족
              </CheckboxLabel>
              <CheckboxLabel>
                <Checkbox
                  value="기타"
                  checked={reasons.includes("기타")}
                  onChange={handleCheckboxChange}
                />
                기타
              </CheckboxLabel>
            </CheckboxContainer>

            <Title2>불편했던 점 건의사항</Title2>
            <Note2>
              떠나는 이유를 자세히 알려주시겠어요? 여러분의 소중한 의견을 반영해
              더 좋은 서비스로 찾아뵙겠습니다.
            </Note2>
            <MobileNote>
              여러분의 소중한 의견을 반영해 더 좋은 서비스로 찾아뵙겠습니다.
            </MobileNote>
            <TextareaContainer>
              <Textarea 
                placeholder="자세한 이유를 남겨주시면 서비스 개선에 큰 도움이 됩니다."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </TextareaContainer>

            <ButtonContainer>
              <Button onClick={handleWithdraw}>탈퇴하기</Button>
              <Button onClick={handleCancel}>취소하기</Button>
            </ButtonContainer>
          </Box>
        </Con>
      </ResponsiveWrapper>
    </>
  );
};

export default WithdrawalPage;
