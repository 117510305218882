// import styled from "styled-components";
import styled from "styled-components";
import PopularMansionItem from "./PopularMansionItem";
import ResponsiveWrapper from "../../../components/ResponsiveWrapper";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { popularMansionItemType } from "../PopularMansion";
import Navigation, { getMaxPage } from "../../../components/Navigation";
import { useEffect, useState } from "react";

function PopularMansionCategory({
  title,
  explain,
  maxItem,
  rooms,
}: {
  title: string;
  explain?: string;
  maxItem?: number;
  rooms?: popularMansionItemType[];
}) {
  const { isMobile } = useDeviceSize();
  const [contentsPageState, setContentsPageState] = useState(0);
  const [currentPageMansions, setCurrentPageMansions] = useState<
    popularMansionItemType[]
  >([]);

  useEffect(() => {
    if (rooms) {
      const itemsPerPage = isMobile ? 2 : 3;
      const startIndex = contentsPageState * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      if (startIndex >= rooms.length && contentsPageState > 0){
        setContentsPageState(contentsPageState -1);
      }
      setCurrentPageMansions(rooms.slice(startIndex, endIndex));
    }
  }, [contentsPageState, rooms, isMobile]);

  return (
    <ResponsiveWrapper>
      <PopularMansionCategoryWrapper>
        <CategoryTop>
          <TitleBox>
            <Title>{title}</Title>
            <Explain>{explain}</Explain>
          </TitleBox>
        </CategoryTop>
        {isMobile ? (
          <ItemWrapper>
            {!rooms || (rooms && rooms?.length < 1) ? (
              <NoItemArea>조건에 해당하는 매물이 없습니다.</NoItemArea>
            ) : (
                <>
                  <Navigation
                      onClickNext={() => {
                        setContentsPageState(contentsPageState + 1);
                      }}
                      onClickPrev={() => {
                        setContentsPageState(contentsPageState - 1);
                      }}
                      page={contentsPageState}
                      maxPage={rooms && getMaxPage(2, rooms.length)}
                      translateY={-70}
                      mobile={true}
                  />
                  {currentPageMansions &&
                      currentPageMansions.map((item, index) => (
                          <PopularMansionItem
                              roomInfo={item}
                              key={index}
                          ></PopularMansionItem>
                      ))}
                </>
            )}
          </ItemWrapper>
        ) : (
          <ItemWrapper>
            {!rooms || (rooms && rooms?.length < 1) ? (
              <NoItemArea>조건에 해당하는 매물이 없습니다.</NoItemArea>
            ) : (
              <>
                <Navigation
                  onClickNext={() => {
                    setContentsPageState(contentsPageState + 1);
                  }}
                  onClickPrev={() => {
                    setContentsPageState(contentsPageState - 1);
                  }}
                  page={contentsPageState}
                  maxPage={rooms && getMaxPage(3, rooms.length)}
                  translateY={-30}
                />
                {currentPageMansions &&
                  currentPageMansions.map((item, index) => (
                    <PopularMansionItem
                      roomInfo={item}
                      key={index}
                    ></PopularMansionItem>
                  ))}
              </>
            )}
          </ItemWrapper>
        )}
      </PopularMansionCategoryWrapper>
    </ResponsiveWrapper>
  );
}

export default PopularMansionCategory;

const PopularMansionCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CategoryTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 1280px) {
    gap: 34px;
    min-height: 298.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 17px;
    min-height: 252px;
  }
  @media screen and (max-width: 1023px) {
    justify-content: space-between;
    min-height: 229px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 1280px) {
    gap: 15.5px;
    margin-bottom: 13.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 7.5px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 1023px) {
    gap: 4.5px;
    margin-bottom: 8px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  @media screen and (min-width: 1280px) {
    font-size: 26px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 26px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
`;

const Explain = styled.div`
  align-self: self-end;
  margin-bottom: 3px;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
`;

const NoItemArea = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #837474;
  @media screen and (min-width: 1280px) {
    font-size: 27px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;
