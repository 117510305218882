import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RegistrationHeader from "./components/RegistrationHeader";
import RegistrationCategory from "./components/RegistrationCategory";
import RegistrationInformation from "./components/RegistrationInformation";
import RegistrationLocation from "./components/RegistrationLocation";
import RegistrationStation from "./components/RegistrationStation";
import RegistrationExplain from "./components/RegistrationExplain";
import RegistrationPoint from "./components/RegistrationPoint";
import RegistrationOptions from "./components/RegistrationOptions";
import { propertyOptionDataType } from "../../recoil/mansionPage/propertyOptionsAtom";
import axiosInstance from "../../services/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import RegistrationDistrict from "./components/RegistrationDistrict";
import {
  registrationDataType,
  useAdminRegistrationState,
} from "../../recoil/mansionManagement/adminRegistrationStateAtom";
import {Helmet} from "react-helmet";

type prevDataResponseType = {
  id: number;
  name: string;
  description: string;
  address: string;
  additionalAddress: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  agency: string;
  detailCategoryId: number;
  districtId: number;
  districtKName: string;
  constructedDate: string;
  totalFloors: number;
  status: "Public" | "Private" | "Tenp";
  option: {
    airConditioning: boolean;
    allElectricMansion: boolean;
    autoLock: boolean;
    bathroomDryer: boolean;
    bicycleParking: boolean;
    bidet: boolean;
    bikeParking: boolean;
    carParking: boolean;
    cctv: boolean;
    cityGas: boolean;
    closeToStation: boolean;
    deliveryBox: boolean;
    designerMention: boolean;
    duplexMention: boolean;
    elevator: boolean;
    foreignerAllowed: boolean;
    freeInternet: boolean;
    furnitureOptions: boolean;
    garbageStorage: boolean;
    gasRange: boolean;
    indoorWashingBoard: boolean;
    induction: boolean;
    intercom: boolean;
    mensOnly: boolean;
    morePeople: boolean;
    outdoorWashingBoard: boolean;
    overseasScreeningAvailable: boolean;
    petsAllowed: boolean;
    standAloneToilet: boolean;
    storageSpace: boolean;
    trashDisposal24H: boolean;
    unitBath: boolean;
    womenOnly: boolean;
  };
  recommendPoints: string;
  // recommendPoints: string[];
  thumbnailImg: {
    fileType: string;
    id: number;
    url: string;
  };
  linkedStation: any[];
};

function Registration() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const navigate = useNavigate();
  const {
    registrationDataState,
    resetRegistrationDataState,
    updateRegistrationDataOptions,
    setRegistrationDataState,
  } = useAdminRegistrationState();

  const validateRegistrationData = () => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    if (
      registrationDataState.selectedCategory === 0 ||
      registrationDataState.districtId === 0 ||
      registrationDataState.name === "" ||
      registrationDataState.agency === "" ||
      registrationDataState.structedYear === "" ||
      registrationDataState.thumbnailImage === null ||
      registrationDataState.zipCode === "" ||
      registrationDataState.address === "" ||
      !datePattern.test(registrationDataState.structedYear) ||
      registrationDataState.latitude === "" ||
      registrationDataState.longitude === "" ||
      registrationDataState.closeStation.length < 1 ||
      registrationDataState.mansionExplain === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validatePutRegistrationData = () => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    if (
      registrationDataState.selectedCategory === 0 ||
      registrationDataState.districtId === 0 ||
      registrationDataState.name === "" ||
      registrationDataState.agency === "" ||
      registrationDataState.structedYear === "" ||
      registrationDataState.zipCode === "" ||
      registrationDataState.address === "" ||
      registrationDataState.totalFloors === "" ||
      !datePattern.test(registrationDataState.structedYear) ||
      registrationDataState.latitude === "" ||
      registrationDataState.longitude === "" ||
      registrationDataState.closeStation.length < 1 ||
      registrationDataState.mansionExplain === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const convertToFormData = (status: "Temp" | "Public"): FormData => {
    const formData = new FormData();

    formData.append("districtId", registrationDataState.districtId.toString());

    formData.append(
      "detailCategoryId",
      registrationDataState.selectedCategory?.toString()
    );
    formData.append("status", status);

    registrationDataState.name !== "" &&
      formData.append("name", registrationDataState.name);
    registrationDataState.agency !== "" &&
      formData.append("agency", registrationDataState.agency);

    registrationDataState.structedYear !== "" &&
      formData.append("constructedDate", registrationDataState.structedYear);

    registrationDataState.totalFloors !== "" &&
      formData.append("totalFloors", registrationDataState.totalFloors);

    if (registrationDataState.thumbnailImage) {
      formData.append("thumbnailImg", registrationDataState.thumbnailImage);
    }

    if (registrationDataState.mansionExplain) {
      formData.append("description", registrationDataState.mansionExplain);
    }

    if (
      registrationDataState.point1 ||
      registrationDataState.point2 ||
      registrationDataState.point3
    ) {
      formData.append(
        "recommendPoints",
        `${
          registrationDataState.point1 &&
          registrationDataState.point1.length > 0
            ? "$" + registrationDataState.point1
            : ""
        }` +
          `${
            registrationDataState.point2 &&
            registrationDataState.point2.length > 0
              ? "$" + registrationDataState.point2
              : ""
          }` +
          `${
            registrationDataState.point3 &&
            registrationDataState.point3.length > 0
              ? "$" + registrationDataState.point3
              : ""
          }`
      );
    }

    Object.keys(registrationDataState.mansionOptionsState).forEach((key) => {
      formData.append(
        `propertyOptionRequest.${key}`,
        registrationDataState.mansionOptionsState[
          key as keyof propertyOptionDataType
        ].toString()
      );
    });

    registrationDataState.zipCode !== "" &&
      formData.append("zipCode", registrationDataState.zipCode);
    registrationDataState.address !== "" &&
      formData.append("address", registrationDataState.address);
    registrationDataState.additionalAddress !== "" &&
      formData.append(
        "additionalAddress",
        registrationDataState.additionalAddress
      );
    registrationDataState.latitude !== "" &&
      formData.append("latitude", registrationDataState.latitude);
    registrationDataState.longitude !== "" &&
      formData.append("longitude", registrationDataState.longitude);

    registrationDataState.closeStation.forEach((station, index) => {
      formData.append(
        `stationOfPropertyList[${index}].stationId`,
        station.stationId.toString()
      );
      formData.append(
        `stationOfPropertyList[${index}].timeConsumed`,
        station.timeConsumed.toString()
      );
    });

    return formData;
  };

  const handleSaveMansion = async () => {
    if (
      registrationDataState.districtId.toString() !== "0" ||
      registrationDataState.selectedCategory.toString() !== "0"
    ) {
      const formData = convertToFormData("Temp");
      if (type && type === "add") {
        try {
          const response = await axiosInstance.post(
            `/admin/property`,
            formData
          );

          if (response.status === 200) {
            alert("매물 임시저장이 완료되었습니다.");
            navigate("/admin/mansionManagement");
          }
        } catch (error) {
          alert("임시저장에 실패했습니다.");
        }
      } else if (type && type === "modify") {
        try {
          const mansionId = queryParams.get("mansionId");
          const response = await axiosInstance.put(
            `/admin/property/${mansionId}`,
            formData
          );

          if (response.status === 200) {
            alert("매물 정보 수정(임시저장)이 완료되었습니다.");
            navigate("/admin/mansionManagement");
          }
        } catch (error) {
          alert("매물 정보 수정(임시저장)에 실패했습니다.");
        }
      }
    } else {
      alert("카테고리와 지역이 선택되어야 임시저장 가능합니다.");
    }
  };

  const handlePostMansion = async () => {
    const formData = convertToFormData("Public");
    if (type && type === "add") {
      if (validateRegistrationData()) {
        try {
          const response = await axiosInstance.post(
            `/admin/property`,
            formData
          );

          if (response.status === 200) {
            alert("매물등록이 완료되었습니다.");
            navigate("/admin/mansionManagement");
          }
        } catch (error) {
          alert("매물등록에 실패했습니다.");
        }
      } else {
        alert("빈 입력값이 있습니다.");
      }
    } else if (type && type === "modify") {
      if (validatePutRegistrationData()) {
        try {
          const mansionId = queryParams.get("mansionId");
          const response = await axiosInstance.put(
            `/admin/property/${mansionId}`,
            formData
          );

          if (response.status === 200) {
            alert("매물 정보 수정이 완료되었습니다.");
            navigate("/admin/mansionManagement");
          }
        } catch (error) {
          alert("매물 정보 수정에 실패했습니다.");
        }
      } else {
        alert("빈 입력값이 있습니다.");
      }
    }
  };

  const fetchPrevData = async (mansionId: string) => {
    try {
      const response: { data: prevDataResponseType } = await axiosInstance.get(
        `/admin/property/${mansionId}`
      );

      const transformedStations = response.data.linkedStation.map(
        (station) => ({
          stationKName: `${station.prefectureKName}/ ${station.lineKName}/ ${station.stationKName}`,
          timeConsumed: station.timeConsumed,
          stationId: station.stationId,
        })
      );

      setRegistrationDataState({
        address: response.data.address,
        additionalAddress: response.data.additionalAddress,
        agency: response.data.agency,
        closeStation: transformedStations,
        districtId: response.data.districtId,
        districtKName: response.data.districtKName,
        name: response.data.name,
        fileUrl: response.data.thumbnailImg.url,
        thumbnailImage: null,
        totalFloors: response.data.totalFloors.toString(),
        latitude: response.data.latitude.toString(),
        longitude: response.data.longitude.toString(),
        mansionExplain: response.data.description,
        mansionOptionsState: response.data.option,
        zipCode: response.data.zipCode,
        selectedCategory: response.data.detailCategoryId,
        structedYear: response.data.constructedDate,
        point1:
          response.data.recommendPoints.split("$")[1] &&
          response.data.recommendPoints.split("$")[1],
        point2:
          response.data.recommendPoints.split("$")[2] &&
          response.data.recommendPoints.split("$")[2],
        point3:
          response.data.recommendPoints.split("$")[3] &&
          response.data.recommendPoints.split("$")[3],
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (!type) {
      navigate("/admin/mansionManagement");
    }

    const mansionId = queryParams.get("mansionId") || null;
    if (type === "modify" && mansionId) {
      fetchPrevData(mansionId);
    }
    return () => {
      resetRegistrationDataState();
    };
  }, []);

  return (
      <>
        <Helmet>
          <meta name="robots" content="noindex,nofollow"/>
        </Helmet>
    <Wrapper>
      <ContentsWrapper>
        <RegistrationHeader title={"매물등록"} path={"매물관리 > 매물등록"} />
        <RegistrationCategory />
        <RegistrationInformation />
        <RegistrationDistrict />
        <RegistrationLocation />
        <RegistrationStation />
        <RegistrationExplain />
        <RegistrationPoint />
        <RegistrationOptions />
      </ContentsWrapper>
      <ButtonArea>
        <SaveButton onClick={handleSaveMansion}>임시저장</SaveButton>
        <RegistrationButton onClick={handlePostMansion}>
          등록하기
        </RegistrationButton>
      </ButtonArea>
    </Wrapper>
        </>
  );
}

export default Registration;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1920px;
  display: flex;
  align-items: center;
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1323px;
  padding: 0 43px;
  background-color: white;
  margin-top: 107px;
  border-radius: 5px;
`;

const ButtonArea = styled.div`
  display: flex;
  gap: 28px;
  margin-top: 58px;
  padding-bottom: 260px;
`;

const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  width: 269px;
  height: 55px;
  border: 3px solid #99393a;
  color: #99393a;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
`;

const RegistrationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  width: 269px;
  height: 55px;
  background-color: #99393a;
  color: white;
  border-radius: 8px;
  cursor: pointer;
`;
