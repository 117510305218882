import styled from "styled-components";
import adersLogo from "../../../assets/images/mainPage/adersLogo.png";

function FooterLeft() {
  return (
    <Wrapper>
      <CompanyProfile>
        <CompanyProfileImage src={adersLogo} />
      </CompanyProfile>
      <CustomerService>고객센터 {">"}</CustomerService>
      <CustomerServiceInfo>
        <div style={{ fontWeight: "bold" }}>+81-6-6655-0328</div>
        <div>평일 10:00~19:00</div>
      </CustomerServiceInfo>
      <ButtonArea>
        <FooterButton
          onClick={() => {
            window.open("https://pf.kakao.com/_qTEPj");
          }}
        >
          카톡 상담
        </FooterButton>
        <FooterButton
          onClick={() => {
            navigator.clipboard.writeText("property@aders-international.com");
            alert(
              `이메일이 클립보드에 복사되었습니다. property@aders-international.com`
            );
          }}
        >
          이메일 문의
        </FooterButton>
      </ButtonArea>
    </Wrapper>
  );
}

export default FooterLeft;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid #c9c9c9;
  @media screen and (min-width: 1280px) {
    width: 30%;
    padding: 7px 0;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 30%;
    padding: 5px 0;
  }
`;

const CompanyProfile = styled.div`
  display: flex;
  align-items: center;
  font-family: "Noto Sans JP";

  @media screen and (min-width: 1280px) {
    gap: 14.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 12px;
  }
`;

const CompanyProfileImage = styled.img`
  @media screen and (min-width: 1280px) {
    width: 36.5px;
    height: 36.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 30px;
    height: 30px;
  }
`;

const CustomerService = styled.div`
  font-weight: bold;

  @media screen and (min-width: 1280px) {
    margin-top: 24.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 20.5px;
  }
`;

const CustomerServiceInfo = styled.div`
  display: flex;

  @media screen and (min-width: 1280px) {
    margin-top: 8px;
    gap: 14px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 6px;
    gap: 9px;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    margin-top: 28.5px;
    gap: 12px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 23px;
    gap: 10px;
  }
`;

const FooterButton = styled.button`
  border: 1px solid #c9c9c9;
  background-color: white;
  border-radius: 10px;
  font-family: "Noto Sans KR";
  cursor: pointer;
  color: #333333;
  @media screen and (min-width: 1280px) {
    padding: 0 10px;
    height: 30px;
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 0 9px;
    height: 25px;
    font-size: 13px;
  }
`;
