import styled from "styled-components";
import { Helmet } from "react-helmet";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import MansionSidebar from "./components/MansionSidebar";
import MansionContents from "./components/MansionContents";
import { useEffect, useState } from "react";
import axiosInstance from "../../services/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { propertyOptionDataType } from "../../recoil/mansionPage/propertyOptionsAtom";
import { paginationDataType } from "../../recoil/mansionPage/paginationStateAtom";
import { filterDataType } from "../../recoil/mansionPage/filterStateAtom";
import usePropertyState from "../../hooks/usePropertyState";
import { useRecoilState } from "recoil";
import { mansionPropertyDataStateAtom } from "../../recoil/mansionPage/mansionPropertyState";

export type mansionPropertyType = {
  propertyOptionRequest: propertyOptionDataType;
} & filterDataType &
  paginationDataType;

export type searchedRoomType = {
  id: number;
  floorPlanUrl: string;
  roomNum: number;
  numOfFloor: number;
  roomType: string;
  area: number;
  rent: number;
  managementFee: number;
  deposit: number;
  gratuity: number;
};

export type mansionDataType = {
  address: string;
  constructedDate: string;
  name: string;
  searchedRoom: searchedRoomType[];
  stationInfo: string[];
  thumbnailUrl: string;
};

export type mansionListDataType = {
  content: mansionDataType[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
  };
  size: number;
  totalElements: number;
  totalPages: number;
};

function Mansion() {
  const navigate = useNavigate();
  const location = useLocation();
  const detailCategoryId = location.state;
  const [mansionState, setMansionState] = useState<mansionListDataType>();
  const [propertyState, setPropertyState] = useRecoilState(
      mansionPropertyDataStateAtom
  );

  const {updatePagination, pagination, updatePropertyOptions} =
      usePropertyState();

  const fetchMansion = async (property: mansionPropertyType) => {
    const response: { data: mansionListDataType } = await axiosInstance.post(
        "property",
        {...property, detailCategoryId: pagination.detailCategoryId}
    );

    setMansionState(response.data);
  };
  useEffect(() => {
    if (!detailCategoryId) {
      updatePagination({detailCategoryId: 1});
    } else {
      updatePagination({detailCategoryId: detailCategoryId});
    }
  }, []);
  useEffect(() => {
    fetchMansion(propertyState);
  }, [propertyState, pagination]);



  return (
      <>
        {/* React Helmet 적용 */}
        <Helmet>
          <title>Houber - Mansion</title>
          <meta name="robots" content="index,follow"/>
          <meta name="description" content="일본 부동산을 최적화된 검색으로"/>
          <meta name="keywords" content="일본 부동산, 일본 임대, 일본 매매, 일본 점포, 일본, 부동산"/>
          <meta property="og:title" content="Houber - 맨션"/>
          <meta property="og:description" content="임대 / 매매 / 점포 모두 Houber"/>
        </Helmet>
        <ResponsiveWrapper>
          <MansionWrapper>
            <MansionSidebar/>
            <MansionContents mansionState={mansionState}/>
          </MansionWrapper>
        </ResponsiveWrapper>
      </>
  );
}

export default Mansion;

const MansionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: 1280px) {
    margin-top: 41px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 51px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 29px;
  }
`;
