import styled from "styled-components";

function FooterMiddle() {
  return (
    <Wrapper>
      <InformationWrapper>
        <div>株式会社エイダス&emsp;</div>
        <div>代表者：李　知炯 </div>
      </InformationWrapper>
        <div>住所：大阪府大阪市中央区松屋町住吉6-8</div>
      <div>宅地建物取引業免許番号：大阪府知事(1)第6236 1号</div>
      <div>行政書士登録番号：24261578</div>
    </Wrapper>
  );
}

export default FooterMiddle;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  border-right: 1px solid #c9c9c9;
    font-family: 'Noto Sans JP', sans-serif;
  @media screen and (min-width: 1280px) {
    width: 40%;
    padding: 7px 0;  
      padding-left: 57px;
    gap: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 45%;
    padding: 5px 0;
      padding-left: 50.5px;
    gap: 15.5px;
  }
`;

const InformationWrapper = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    gap: 10px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 9px;
  }
`;
