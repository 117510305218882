import { useCallback, useEffect, useRef, useState } from 'react';
import usePropertyState from '../../hooks/usePropertyState';
import { mansionPropertyDataStateAtom } from '../../recoil/mansionPage/mansionPropertyState';
import axiosInstance from '../../services/axiosConfig';
import { mansionPropertyType } from '../mansionPage/Mansion';
import { SidebarOptionsWrapper, WebFilterList, Wrapper, Option } from './components/Map.style'
import MapContents from './components/MapContents'
import MapTop from './components/MapTop'
import { useRecoilState } from "recoil";
import MansionSidebarbottom from '../mansionPage/components/MansionSidebarbottom';
import MansionSidebarTop from '../mansionPage/components/MansionSidebarTop';
import MapFliterList from './components/MapFliterList';
import MapPinDetail from './components/MapPinDetail';
import MyLocation from './components/MyLocation';
import {Helmet} from "react-helmet";

export interface MarkerProps {
  id: number
  lat:	number
  lng:	number
}
export interface locationI{
  lat:	number
  lng:	number
}
export interface searchedMapDataI{
  key:string;
  ratio: number;
  lat:number;
  lng:number;
  simpleResponseList: MarkerProps[] | []
}

export default function Map() {
  const mapRef = useRef<HTMLDivElement>(null);

  // 핀 선택 매물 정보 여닫기 및 선택 매물 번호
  const [pinDetail, setPinDetail] = useState<number>(-1);

  //조건 창 여닫기
  const [showFilterList, setShowFilterList] = useState<boolean>(false);
  //검색 api 옵션 라인
  const [propertyState, setPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );
  const {pagination } = usePropertyState();
  const [searchedMapData, setSearchedMapData] = useState<searchedMapDataI>({
    key:"",
    ratio: 200001,
    lat: 37.309435,
    lng: 138.690981,
    simpleResponseList: [],
  });
  const [search, setSearch] = useState<boolean>(true)
  const [location, setLocation] = useState<locationI>({
    lat: 0,
    lng: 0
  })

  const fetchMansion = async (property: mansionPropertyType) => {
    try {
      const response: { data: searchedMapDataI } = await axiosInstance.post(
        "/map/google/search",
        { ...property, detailCategoryId: pagination.detailCategoryId }
      );
      setSearchedMapData(response.data);

    } catch (error) {
      console.log("error", error)
      setSearchedMapData({
        key:"",
        ratio: 200001,
        lat: 37.309435,
        lng: 138.690981,
        simpleResponseList: [],
      });
      alert('검색 결과가 없습니다. 다른 조건으로 검색해주세요.')
    }
    setSearch(false);
  };

  const fetchMansionRoomList = async (neLat: string, neLng: string, swLat: string ,swLng: string ) => {
    if(searchedMapData.key === ""){
      return;
    }
    try {
      const response: { data: MarkerProps[] } = await axiosInstance.get(
        `/map/google?key=${searchedMapData.key}&neLat=${neLat}&neLng=${neLng}&swLat=${swLat}&swLng=${swLng}`);
      setSearchedMapData(prev=> ({
        key:prev.key,
        ratio: prev.ratio,
        lat: prev.lat,
        lng: prev.lng,
        simpleResponseList: response.data,
      }));

    } catch (error) {
      console.log("error", error)
      fetchMansion(propertyState)
    }
    setSearch(false);
  };

  useEffect(()=>{
    if(search){
      fetchMansion(propertyState);
    }
  },[search, pagination.detailCategoryId])

  return (
      <>
        <Helmet>
          <title>Houber - Mansion</title>
          <meta name="robots" content="noindex,nofollow"/>
        </Helmet>
    <Wrapper>
      <MapTop showFilterList={showFilterList} setShowFilterList={setShowFilterList}/>
      <MapContents location={location} fetchMansionRoomList={fetchMansionRoomList} mapRef={mapRef} setShowFilterList={setShowFilterList} searchedMapData={searchedMapData} setPinDetail={setPinDetail} pinDetail={pinDetail}/>
      {showFilterList && <MapFliterList setSearch={setSearch} showFilterList={showFilterList} setShowFilterList={setShowFilterList}/>}
      {pinDetail!==-1 && <MapPinDetail showFilterList={showFilterList}  pinDetail={pinDetail} setPinDetail={setPinDetail} pinGetKey={searchedMapData.key}/>}
      <MyLocation onLocationFound={setLocation} pinDetail={pinDetail}/>
    </Wrapper>
        </>
  )
}

