// import styled from "styled-components";
import styled from "styled-components";
import scrap from "../../../assets/images/mainPage/scrap.png";
import scrapColord from "../../../assets/images/mainPage/scrap-colored.png";
import { houberMansionItemType } from "../HouberMansion";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axiosConfig";
import { useHouberMansionData } from "../../../recoil/mainPge/houberMansionDataStateAtom";

type houberMansionResponseType = {
  data: {
    roomDetails: houberMansionItemType[];
  };
};

function HouberMansionItem({
  roomInfo,
  getMainContentsState,
}: {
  roomInfo: houberMansionItemType;
  getMainContentsState: (newItemState: {
    thumbnailUrl: string;
    roomId: number;
  }) => void;
}) {
  const scrapState = roomInfo.isScrapbook;

  const { setHouberMansionDataState } = useHouberMansionData();

  const navigate = useNavigate();

  const onClickThumbnail = () => {
    navigate(`/detail?roomId=${roomInfo.id}`);
  };

  const fetchHouberMansion = async () => {
    const response: houberMansionResponseType = await axiosInstance.get(
      "/normal/home/detailHouber"
    );

    setHouberMansionDataState(response.data.roomDetails);
  };

  const onClickScrap = async (e: React.MouseEvent) => {
    const token = window.sessionStorage.getItem("Authorization");

    e.stopPropagation();
    if (token) {
      try {
        const response = await axiosInstance.post(`/normal/user/scrap`, {
          category: "room",
          entityId: roomInfo?.id,
        });

        if (response.status === 200) {
          if (scrapState) {
            alert("스크랩 취소 되었습니다.");
            fetchHouberMansion();
          } else {
            alert("스크랩 되었습니다.");
            fetchHouberMansion();
          }
        }
      } catch (error) {
        alert("스크랩에 실패했습니다");
        console.log(error);
      }
    } else {
      alert("로그인 후 이용할 수 있는 서비스 입니다.");
      navigate("/login");
    }
  };

  const tagsString =
    roomInfo?.recommendPoints
      ?.split("$")
      .filter((item) => item !== "")
      .map((item) => `#${item}`)
      .join(" ") || "";

  return (
    <HouberMansionItemWrapper>
      <ImageDiv
        onClick={onClickThumbnail}
        onMouseOver={() => {
          getMainContentsState({
            roomId: roomInfo?.id,
            thumbnailUrl: roomInfo.thumbnailUrl,
          });
        }}
      >
        <StyledImage src={roomInfo.thumbnailUrl}></StyledImage>
        <ScrapButton
          src={roomInfo.isScrapbook ? scrapColord : scrap}
          onClick={(event) => onClickScrap(event)}
        />
      </ImageDiv>
      <ItemRight>
        <ItemTitle>{roomInfo.name}</ItemTitle>
        <ItemTag>{tagsString}</ItemTag>
      </ItemRight>
    </HouberMansionItemWrapper>
  );
}

export default HouberMansionItem;

const HouberMansionItemWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px 0 0 50px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    width: 448.5px;
    height: 195px;
    padding: 10px;
    gap: 17.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 371px;
    height: 161.5px;
    padding: 8.5px;
    gap: 14.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 385px;
    height: 167px;
    padding: 8.5px;
    gap: 27px;
  }

  &:hover {
    background-color: #f7ecec;
  }
`;

const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  border-radius: 40px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  @media screen and (min-width: 1280px) {
    width: 178px;
    height: 100%;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 148px;
    height: 100%;
  }
  @media screen and (max-width: 1023px) {
    width: 152px;
    height: 100%;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 40px;
`;

const ItemRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media screen and (min-width: 1280px) {
    width: 221px;
    gap: 6px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 180px;
    gap: 5.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 166px;
    gap: 11px;
  }
`;

const ItemTitle = styled.div`
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  @media screen and (min-width: 1280px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;

const ItemTag = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-weight: 500;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
    margin-top: 7.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
    margin-top: 4.5px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
`;

const ScrapButton = styled.img`
  display: block;
  position: absolute;
  @media screen and (min-width: 1280px) {
    width: 17.5px;
    height: 17.5px;
    right: 17px;
    bottom: 17px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
  @media screen and (max-width: 1023px) {
    width: 19.5px;
    height: 19.5px;
    right: 15px;
    bottom: 15px;
  }
`;
