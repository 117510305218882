import styled from "styled-components";
import InformationBox from "./InformationBox";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { roomDetailType } from "../Detail";
import selected from "../../../assets/images/detail/selected.png";

function DetailOptions({
  roomDetailState,
}: {
  roomDetailState?: roomDetailType;
}) {
  const { isMobile } = useDeviceSize();

  const OPTIONS = [
    { name: "인터넷 무료", propertyName: "freeInternet" },
    { name: "2인 이상 입주", propertyName: "morePeople" },
    { name: "복층 맨션", propertyName: "duplexMention" },
    { name: "인덕션", propertyName: "induction" },
    { name: "올 전기 맨션", propertyName: "allElectricMansion" },
    { name: "실내 세탁판", propertyName: "indoorWashingBoard" },
    { name: "비데", propertyName: "bidet" },
    { name: "유닛베스", propertyName: "unitBath" },
    { name: "냉난방", propertyName: "airConditioning" },
    { name: "24시 쓰레기 배출", propertyName: "trashDisposal24H" },
    { name: "인터폰", propertyName: "intercom" },
    { name: "자동차 주차장", propertyName: "carParking" },
    { name: "자전거 주차장", propertyName: "bicycleParking" },
    { name: "여성 전용 맨션", propertyName: "womenOnly" },
    { name: "오토록", propertyName: "autoLock" },
    { name: "외국인 가능", propertyName: "foreignerAllowed" },
    { name: "가구옵션", propertyName: "furnitureOptions" },
    { name: "펫가능", propertyName: "petsAllowed" },
    { name: "디자이너 맨션", propertyName: "designerMention" },
    { name: "역세권", propertyName: "closeToStation" },
    { name: "가스레인지", propertyName: "gasRange" },
    { name: "도시가스", propertyName: "cityGas" },
    { name: "실외 세탁판", propertyName: "outdoorWashingBoard" },
    { name: "독립형 화장실", propertyName: "standAloneToilet" },
    { name: "욕실 건조기", propertyName: "bathroomDryer" },
    { name: "수납공간", propertyName: "storageSpace" },
    { name: "쓰레기 보관소", propertyName: "garbageStorage" },
    { name: "택배박스", propertyName: "deliveryBox" },
    { name: "엘레베이터", propertyName: "elevator" },
    { name: "바이크 주차장", propertyName: "bikeParking" },
    { name: "남성 전용 맨션", propertyName: "mensOnly" },
    { name: "CCTV", propertyName: "cctv" },
    { name: "해외심사 가능", propertyName: "overseasScreeningAvailable" },
  ];

  const convertedIndex = (page: number) => {
    if (!isMobile) {
      return { start: 11 * (page - 1), end: 11 * page };
    } else {
      return { start: 17 * (page - 1), end: 17 * page };
    }
  };

  return (
    <DetailOptionsWrapper>
      <InformationBox
        width={{ pc: "100%", laptop: "100%", mobile: "100%" }}
        height={{ pc: "502px", laptop: "470px", mobile: "250px" }}
      >
        <OptionsInner>
          <ScrollContainer>
            <ItemWrapper>
              {OPTIONS.slice(
                convertedIndex(1).start,
                convertedIndex(1).end
              ).map((item, index) => (
                <OptionItem key={index}>
                  {roomDetailState?.option[
                    item.propertyName as keyof typeof roomDetailState.option
                  ] ? (
                    <SelectedOptionIcon src={selected} />
                  ) : (
                    <OptionIcon />
                  )}
                  <OptionName>{item.name}</OptionName>
                </OptionItem>
              ))}
            </ItemWrapper>
            <ItemWrapper>
              {OPTIONS.slice(
                convertedIndex(2).start,
                convertedIndex(2).end
              ).map((item, index) => (
                <OptionItem key={index}>
                  {roomDetailState?.option[
                    item.propertyName as keyof typeof roomDetailState.option
                  ] ? (
                    <SelectedOptionIcon src={selected} />
                  ) : (
                    <OptionIcon />
                  )}
                  <OptionName>{item.name}</OptionName>
                </OptionItem>
              ))}
            </ItemWrapper>
            {!isMobile && (
              <>
                <ItemWrapper>
                  {OPTIONS.slice(
                    convertedIndex(3).start,
                    convertedIndex(3).end
                  ).map((item, index) => (
                    <OptionItem key={index}>
                      {roomDetailState?.option[
                        item.propertyName as keyof typeof roomDetailState.option
                      ] ? (
                        <SelectedOptionIcon src={selected} />
                      ) : (
                        <OptionIcon />
                      )}
                      <OptionName>{item.name}</OptionName>
                    </OptionItem>
                  ))}
                </ItemWrapper>
              </>
            )}
          </ScrollContainer>
        </OptionsInner>
      </InformationBox>
    </DetailOptionsWrapper>
  );
}

export default DetailOptions;

const DetailOptionsWrapper = styled.div`
  background-color: #f7f9fa;
  @media screen and (min-width: 1280px) {
    margin-top: 52px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 42px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 21px;
  }
`;

const OptionsInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 1280px) {
    padding: 47px 0;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 44px 0;
  }
  @media screen and (max-width: 1023px) {
    padding: 37px 0 37px 0;
    overflow: hidden;
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 1px;
  @media screen and (min-width: 1280px) {
    padding: 0 75px 0 33px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 0 61px 0 31px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 52px 0 21px;
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    gap: 18px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 17px;
  }
  @media screen and (max-width: 1023px) {
    gap: 18px;
  }
`;

const OptionItem = styled.div`
  display: flex;
  gap: 14px;
`;

const OptionIcon = styled.div`
  border-radius: 5px;
  background-color: #eaebef;
  @media screen and (min-width: 1280px) {
    width: 21px;
    height: 21px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 20.5px;
    height: 20.5px;
  }
`;

const SelectedOptionIcon = styled.img`
  border-radius: 5px;
  @media screen and (min-width: 1280px) {
    width: 21px;
    height: 21px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 19.5px;
    height: 19.5px;
  }
  @media screen and (max-width: 1023px) {
    width: 20.5px;
    height: 20.5px;
  }
`;

const OptionName = styled.div`
  @media screen and (min-width: 1280px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;
