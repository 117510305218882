import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import SlideLeft from "../../../assets/images/ResidentReview/SlideLeft.png";
import SlideRight from "../../../assets/images/ResidentReview/SlideRight.png";

interface ImageData {
  images?: string[];
}

const SwiperComponent: React.FC<ImageData> = ({ images }) => {
  const { isLaptop, isMobile, isPC } = useDeviceSize();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSmaller, setIsSmaller] = useState(false); 
  const itemsPerPage = isMobile ? 4 : 7;

  const totalImages = images?.length || 0;

  const currentPageImages = images?.slice(
    Math.floor(activeIndex / itemsPerPage) * itemsPerPage,
    Math.floor(activeIndex / itemsPerPage) * itemsPerPage + itemsPerPage
  ) || [];

  useEffect(() => {
    const img = new Image();
    img.src = images ? images[activeIndex] : "";
    img.onload = () => {
      const containerWidth = isLaptop || isPC ? 780 : 385.5;
      const containerHeight = isLaptop || isPC ? 500 : 385.5;

      if (img.naturalWidth < containerWidth && img.naturalHeight < containerHeight) {
        setIsSmaller(true);
      } else {
        setIsSmaller(false);
      }
    };
  }, [images, activeIndex, isLaptop, isPC]);

  const handleThumbnailClick = (index: number) => {
    setActiveIndex(index + Math.floor(activeIndex / itemsPerPage) * itemsPerPage);
  };

  const handleNextPage = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const handlePrevPage = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? totalImages - 1 : prevIndex - 1
    );
  };

  const isFirstPage = activeIndex === 0;
  const isLastPage = activeIndex === totalImages - 1;

  return (
    <Container>
      {images && images.length > 0 ? (
        <>
          <MainImageContainer isLaptop={isLaptop} isPC={isPC}>
            <MainImage
              isLaptop={isLaptop}
              isPC={isPC}
              src={images[activeIndex]}
              alt={`Slide ${activeIndex + 1}`}
              isSmaller={isSmaller} // 이미지 크기에 따른 스타일 적용
            />
          </MainImageContainer>

          <ThumbsContainer>
            <NavigationButton
              onClick={handlePrevPage}
              className="left"
              transparent={isFirstPage}
            >
              <img src={SlideLeft} alt="Previous Slide" />
            </NavigationButton>

            <ThumbnailWrapper>
              {currentPageImages.map((image, index) => (
                <Thumbnail
                  key={index}
                  src={image}
                  isSelected={activeIndex === index + Math.floor(activeIndex / itemsPerPage) * itemsPerPage}
                  onClick={() => handleThumbnailClick(index)}
                  alt={`Thumbnail ${index + 1}`}
                />
              ))}
            </ThumbnailWrapper>

            <NavigationButton
              onClick={handleNextPage}
              transparent={isLastPage}
            >
              <img src={SlideRight} alt="Next Slide" />
            </NavigationButton>
          </ThumbsContainer>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};
export default SwiperComponent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 50px;

  @media screen and (max-width: 1023px) {
    padding-top: 20px;
  }
`;

const MainImageContainer = styled.div<{ isLaptop: boolean; isPC: boolean }>`
  width: ${(props) => (props.isLaptop || props.isPC ? "780px" : "385.5px")};
  height: ${(props) => (props.isLaptop || props.isPC ? "500px" : "385.5px")};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
`;

const MainImage = styled.img<{ isLaptop: boolean; isPC: boolean; isSmaller: boolean }>`
  object-fit: contain;
  border-radius: 10px;
  ${(props) =>
    props.isSmaller
      ? `
    width: auto;
    height: 100%;
  `
      : `
    max-width: 100%;
    max-height: 100%;
  `}
`;

const ThumbsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 23px;
  justify-content: center;
  @media screen and (max-width: 1023px) {
    width: 385px;
  }
`;

const ThumbnailWrapper = styled.div`
justify-content: center;
  display: flex;
  gap: 10px;
  width: 780px;
  @media screen and (max-width: 1023px) {
    min-width: 385px;
  }
`;

const Thumbnail = styled.img<{ isSelected: boolean }>`
  width: 104px;
  height: 95px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    width: 91px;
    height: 91px;
  }
`;

const NavigationButton = styled.div<{ transparent?: boolean }>`
  border-radius: 50%;
  ${(props) => (props.className === "left" ? "transform: translateX(25px);" : "transform: translateX(-25px);")}
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  cursor: ${(props) => (props.transparent ? "default" : "pointer")};
  opacity: ${(props) => (props.transparent ? "0" : "1")};
  pointer-events: ${(props) => (props.transparent ? "none" : "auto")};
  img {
    width: 55px;
    height: 55px;
    @media screen and (max-width: 1023px) {
      width: 35px;
      height: 35px;
    }
  }
`;
