// import styled from "styled-components";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import SearchRentalMansions from "./components/SearchRentalMansions";
import SearchHouberMansions from "./components/SearchHouberMansions";
import SearchMansionReviews from "./components/SearchMansionReviews";
import SearchQna from "./components/SearchQna";
import { useLocation, useSearchParams} from "react-router-dom";
import axiosInstance from "../../services/axiosConfig";
import { useEffect, useState } from "react";
import SearchPurchaseMansions from "./components/SearchPurchaseMansions";
import SearchStores from "./components/SearchStores";
import { mainMansionDataType, reviewDataType } from "../mainPage/Main";
import styled from "styled-components";
import { useSearchResultData } from "../../recoil/searchPage/searchResultDataStateAtom";
import counselingKakao from "../../assets/images/mainPage/counseling-kakao.png";
import upIcon from "../../assets/images/mainPage/arrow-up.png";

export type searchResultDataType = {
  propertySearched: {
    houber: mainMansionDataType[];
    houberCount: number;
    purchase: mainMansionDataType[];
    purchaseCount: number;
    rent: mainMansionDataType[];
    rentCount: number;
    store: mainMansionDataType[];
    storeCount: number;
  };
  qnaSearched: {
    answerStatus: false;
    content: number;
    createAt: string;
    id: number;
    keywords: string[];
    title: string;
    userNickName: string;
    userProfileImage: string;
  }[];
  reviewSearched: reviewDataType[];
};

function Search() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // 1. location.state에서 keyword와 id를 가져옴
  const keywordFromState = location.state?.keyword;
  const idFromState = location.state?.id;

  // 2. URL 쿼리 파라미터에서 keyword와 id를 가져옴
  const keywordFromParams = searchParams.get("keyword");
  const idFromParams = searchParams.get("id");

  // 3. 우선순위를 정해 keyword와 id를 결정
  const keyword = keywordFromState || keywordFromParams;
  const id = idFromState || idFromParams;

  const { searchResultDatastate, setSearchResultDataState } =
    useSearchResultData();

  const fetchSearchData = async () => {
    if (id || keyword) {
      if (id) {
        const response = await axiosInstance.get("/normal/home/search", {
          params: { keyId: id },
        });

        setSearchResultDataState(response.data);
      } else if (keyword) {
        const response = await axiosInstance.get("/normal/home/search", {
          params: { keyword: keyword },
        });

        setSearchResultDataState(response.data);
      }
    }
  };

  const hasSearchResults = (
    data: searchResultDataType | undefined
  ): boolean => {
    if (!data) return false;

    const {
      propertySearched: { houber, purchase, rent, store },
      qnaSearched,
      reviewSearched,
    } = data;

    return (
      (houber && houber.length > 0) ||
      (purchase && purchase.length > 0) ||
      (rent && rent.length > 0) ||
      (store && store.length > 0) ||
      (qnaSearched && qnaSearched.length > 0) ||
      (reviewSearched && reviewSearched.length > 0)
    );
  };

  useEffect(() => {
    fetchSearchData();
  }, [location]);



  return (
    <>
      <RemoteControllerWrapper>
        <RemoteController>
          <StyledImage
            src={counselingKakao}
            onClick={() => {
              window.open("https://pf.kakao.com/_qTEPj");
            }}
          ></StyledImage>
          <StyledImage
            src={upIcon}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          ></StyledImage>
        </RemoteController>
      </RemoteControllerWrapper>
      <ResponsiveWrapper>
        <>
          {!hasSearchResults(searchResultDatastate) && (
            <NoResultDiv>검색 결과가 없습니다.</NoResultDiv>
          )}
        </>
        {searchResultDatastate &&
          searchResultDatastate?.propertySearched.rent.length > 0 && (
            <SearchRentalMansions
              rentList={searchResultDatastate.propertySearched.rent}
              count={searchResultDatastate.propertySearched.rentCount}
            />
          )}
        {searchResultDatastate &&
          searchResultDatastate?.propertySearched.purchase.length > 0 && (
            <SearchPurchaseMansions
              purchasetList={searchResultDatastate.propertySearched.purchase}
              count={searchResultDatastate.propertySearched.purchaseCount}
            />
          )}
        {searchResultDatastate &&
          searchResultDatastate?.propertySearched.store.length > 0 && (
            <SearchStores
              storetList={searchResultDatastate.propertySearched.store}
              count={searchResultDatastate.propertySearched.storeCount}
            />
          )}
        {searchResultDatastate &&
          searchResultDatastate?.propertySearched.houber.length > 0 && (
            <SearchHouberMansions
              houberList={searchResultDatastate.propertySearched.houber}
              count={searchResultDatastate.propertySearched.houberCount}
            />
          )}
        {searchResultDatastate &&
          searchResultDatastate?.reviewSearched.length > 0 && (
            <SearchMansionReviews
              reviewList={searchResultDatastate.reviewSearched}
              count={searchResultDatastate.reviewSearched.length}
            />
          )}
        {searchResultDatastate &&
          searchResultDatastate?.qnaSearched.length > 0 && (
            <SearchQna
              qnaList={searchResultDatastate.qnaSearched}
              count={searchResultDatastate.qnaSearched.length}
            />
          )}
      </ResponsiveWrapper>
    </>
  );
}

export default Search;

const NoResultDiv = styled.div`
  display: flex;
  width: 100%;
  height: 70vh;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    font-size: 30px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 25px;
  }
  @media screen and (max-height: 1023px) {
    font-size: 20px;
  }
`;

const RemoteControllerWrapper = styled.div`
  position: fixed;
  height: 70vh;
  z-index: 1000;
  pointer-events: none;
  @media screen and (min-width: 1280px) {
    width: 1280px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
  }
`;

const RemoteController = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  bottom: 10%;
  pointer-events: auto;
  @media screen and (min-width: 1280px) {
    right: 60px;
    width: 45px;
    height: 95px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    right: 45px;
    width: 52px;
    height: 110px;
  }
  @media screen and (max-width: 1023px) {
    right: 20px;
    width: 37px;
    height: 83px;
  }
`;

const StyledImage = styled.img`
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 45px;
    height: 45px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 52px;
    height: 52px;
  }
  @media screen and (max-width: 1023px) {
    width: 37px;
    height: 37px;
  }
`;
