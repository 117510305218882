import styled from "styled-components";
import ResponsiveWrapper from "../../ResponsiveWrapper";
import loginIcon from "../../../assets/images/mainPage/sign-in.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import usePropertyState from "../../../hooks/usePropertyState";
import { useSidebarState } from "../../../recoil/mainPge/sidebarStateAtom";
import arrowIcon from "../../../assets/images/header/mobileSidebarArrow.png";

function SidebarNavigationItem({
  children,
  categoryIdList,
  path,
}: {
  children: string;
  categoryIdList?: number[];
  path?: string;
}) {
  const navigate = useNavigate();
  const { updatePagination } = usePropertyState();
  const [categoryState, setCategoryState] = useState<boolean>(false);

  const { modifySidebarState } = useSidebarState();

  const convertedCategoryId = (number: number) => {
    switch (number) {
      case 1:
        return "맨션";
      case 2:
        return "주택";
      case 3:
        return "단층맨션";
      case 4:
        return "기타";
      case 5:
        return "토지";
      case 6:
        return "맨션";
      case 7:
        return "점포";
      case 8:
        return "주택";
      case 9:
        return "기타";
      case 10:
        return "사무실";
      case 11:
        return "창고";
      case 12:
        return "점포";
      case 13:
        return "기타";
      case 14:
        return "하우버맨션";
    }
  };

  const handleOnClickNavigation = () => {
    if (categoryIdList && categoryIdList[0] !== 14) {
      setCategoryState(!categoryState);
    } else if (categoryIdList && categoryIdList[0] === 14) {
      navigate("/mansion", { state: 14 });
      updatePagination({ detailCategoryId: 14 });
      modifySidebarState();
    } else if (path) {
      navigate(path);
      modifySidebarState();
    }
  };
  return (
    <>
      <Wrapper onClick={handleOnClickNavigation}>
        {children}
        <StyledArrowIcon src={arrowIcon}></StyledArrowIcon>
      </Wrapper>
      {categoryState && (
        <CategoryItemWrapper>
          {categoryIdList &&
            categoryIdList.map((item, index) => (
              <CategoryItem
                key={index}
                onClick={() => {
                  navigate("/mansion", { state: item });
                  updatePagination({ detailCategoryId: item });
                  modifySidebarState();
                }}
              >
                {convertedCategoryId(item)}
              </CategoryItem>
            ))}
        </CategoryItemWrapper>
      )}
    </>
  );
}

export default SidebarNavigationItem;

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
  background-color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 15px 0;
  cursor: pointer;
`;

const StyledArrowIcon = styled.img`
  width: 10px;
  height: 17px;
`;

const CategoryItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 29.5px 0 29.5px 10px;
  background-color: #F5F5F5;
  font-size: 15px;
  font-weight: 500;
  row-gap: 25px;
  cursor: pointer;
`;

const CategoryItem = styled.div`
  width: 50%;
`;
