import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import ProfileIMG from "../../assets/images/ResidentReview/ProfileIMG.png";
import Header from "./components/Header";
import Mension from "../../assets/images/ResidentReview/Mension.png";
import PersonIcon from "../../assets/images/ResidentReview/PersonIcon.png";
import Onek from "../../assets/images/ResidentReview/1k.png";
import LocationIcon from "../../assets/images/ResidentReview/LocationIcon.png";
import SwiperComponent from "./components/Slider";
import Footer from "./components/Footer";
import { useParams } from "react-router-dom";
import axiosInstance from "../../services/axiosConfig";
import {Helmet} from "react-helmet";

interface Review {
  title: string;
  buildingType: string;
  residentsCount: number;
  roomType: string;
  districtId: string;
  reviewImg: string[];
  content: string;
  userNickName: string;
  createDate: string;
  isRecommend: boolean;
  thumbnail: string;
  isScrapped: boolean;
}

const ReviewDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [review, setReview] = useState<Review>();
  const [hasgtags, setHashTangs] = useState<string[]>();
  const [districtName, setDistrictName] = useState<string>("");
  const [profileIMG, setProfileIMG] = useState<string>();

  useEffect(() => {
    if (id) {
      const fetchReview = async () => {
        try {
          const res = await axiosInstance.get(
            `https://houberapp.com/review/detail/${id}`
          );
          setReview(res.data);
          setHashTangs(res.data.keyword);
          setProfileIMG(res.data.userProfileImage);
          if (res.data.prefectureName !== null) {
            setDistrictName(
              `${res.data.prefectureName} ${res.data.districtName}`
            );
          }
        } catch (error) {}
      };
      fetchReview();
    }
  }, [id]);

  return (
      <>
        <Helmet>
          <title>Houber - review</title>
          <meta name="robots" content="index,follow"/>
          <meta name="description" content={`${id}번 질문`}/>
          <meta name="keywords" content="부동산, 입주 후기, 입주, 후기, 일본 워홀, 워킹홀리데이, 일본 부동산"/>
          <meta property="og:title" content="Houber-입주후기"/>
          <meta property="og:description" content={`${id}번 입주후기`}/>
        </Helmet>
    <ReviewDetailContainer>
      <IMG>
        <img src={review?.thumbnail} />
      </IMG>
      <ResponsiveWrapper>
        <CenterDiv>
          <Contents>
            <Title>{review?.title} </Title>
            <Header
              profileIMG={profileIMG}
              userNickname={review?.userNickName}
              createAt={review?.createDate}
              id={id}
              isRecommend={review?.isRecommend}
            />
            <CategoryContainer>
              <CategoryDiv className="layout">
                <IconTextWrapper>
                  <IconText>
                    <img src={Mension} alt="Mension" />
                    <div>{review?.buildingType}</div>
                  </IconText>
                  <TextSeperator />
                  <IconText>
                    <img src={PersonIcon} alt="PersonIcon" />
                    {review?.residentsCount ? (
                      <div>{review.residentsCount}인 입주</div>
                    ) : (
                      <div />
                    )}
                  </IconText>
                  <TextSeperator />
                  <IconText>
                    <img src={Onek} alt="Onek" />
                    <div>{review?.roomType}</div>
                  </IconText>
                  <TextSeperator />
                  <IconText>
                    <img src={LocationIcon} alt="LocationIcon" />
                    <div>{districtName}</div>
                  </IconText>
                </IconTextWrapper>
              </CategoryDiv>
              <Seperator />

              <CategoryDiv className="second">
                <MobileLayout>
                  <Hashtags>
                    {hasgtags?.map((tag, index) => (
                      <div
                        key={index}
                        style={{
                          padding: "5px",
                          margin: "5px",
                          borderRadius: "4px",
                        }}
                      >
                        {"#" + tag}
                      </div>
                    ))}
                  </Hashtags>
                </MobileLayout>
              </CategoryDiv>
            </CategoryContainer>
            <SwiperComponent images={review?.reviewImg} />

            <ContentsDiv>{review?.content}</ContentsDiv>
          </Contents>
        </CenterDiv>
      </ResponsiveWrapper>
      <Footer
        id={id}
        createAt={review?.createDate}
        isScrapped={review?.isScrapped}
      />
    </ReviewDetailContainer>
        </>
  );
};

export default ReviewDetail;
const CategoryContainer = styled.div`
  background-color: #f7f9fa;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  margin-top: 20px;
  padding: 0 10px;
  @media screen and (max-width: 1023px) {
    border-top: 1px solid rgba(98, 98, 98, 0.2);
    width: 425px;
    margin-top: 10px;
  }
`;

const CategoryDiv = styled.div`
  width: 100%;
  min-height: 99px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.layout {
    margin: 38px 0 16px 0;
    min-height: unset;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
    min-height: 102px;
    background-color: white;
    border-radius: 0;
    &.layout {
      margin: 0;
    }
  }

`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

  const IMG = styled.div`
    height: 370px;
    width: 820px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      filter: brightness(80%);
    }
    @media screen and (max-width: 1023px) {
      width: 425px;
      height: 300px;
    }
  `;

const Contents = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 780px;

  @media screen and (max-width: 1023px) {
    width: 425px;
  }
`;

const Title = styled.div`
  font-size: 38px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
  text-align: left;
  padding-left: 5px;
  @media screen and (max-width: 1023px) {
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 10px;
    padding-left: 19px;
  }
`;

const ReviewDetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

const IconTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  @media screen and (max-width: 1023px) {
    gap: 0px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  width: auto;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }

  div {
    min-width: 96px;
    max-width: 130px;

    @media screen and (max-width: 1023px) {
      font-size: 12px;
      width: 100px;
    }
  }
  img {
    width: 47px;
    height: 47px;
    @media screen and (max-width: 1023px) {
      width: 42px;
      height: 42px;
      margin-right: 0;
    }
  }
`;

const Hashtags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    padding: 20px 30px;
  }

  div {
    background-color: transparent;
    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  }
`;

const Seperator = styled.div`
  width: 94%;
  height: 1px;
  background-color: rgba(167, 167, 167, 0.3);

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const ContentsDiv = styled.pre`
  width: 100%;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 150px;
  font-size: 19px;
  line-height: 1.5;
  font-family: "Noto Sans KR";
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  @media screen and (max-width: 1023px) {
    width: 90%;
    padding: 0 30px;
    font-size: 14px;
  }
`;

const TextSeperator = styled.div`
  background-color: #a7a7a7;
  width: 1.5px;
  height: 20px;
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const MobileLayout = styled.div`
  @media screen and (max-width: 1023px) {
    background-color: #f7f9fa;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
