import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AutoLoginCheckbox from './AutoLoginCheckbox';

interface LoginFormProps {
  id: string;
  password: string;
  setId: (id: string) => void;
  setPassword: (password: string) => void;
  autoLogin: boolean;
  handleAutoLoginChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  handleLogin: (id:string,password:string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  id,
  password,
  setId,
  setPassword,
  autoLogin,
  handleAutoLoginChange,
  onSubmit,
}) => (
  <StyledForm onSubmit={onSubmit}>
    <InputBox>
      <StyledLabel>
        <StyledInput
          placeholder="아이디"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
      </StyledLabel>

      <StyledDiv />

      <StyledLabel>
        <StyledInput
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </StyledLabel>
    </InputBox>

    <FunCBOx>
      <AutoLogin>
        <AutoLoginCheckbox autoLogin={autoLogin} handleAutoLoginChange={handleAutoLoginChange} />
      </AutoLogin>
      <CheckboxContainer>
        <Link to="/login/findidpw" style={{ textDecoration: 'none', color: 'inherit' }}>
          <div>아이디 <p style={{margin:'0', padding:'0',display:'contents'}}>⦁</p> 비밀번호 찾기{" >"}</div>
        </Link>
      </CheckboxContainer>
    </FunCBOx>

    <LoginSignUpBox>
      <LoginBTN type="submit">로그인</LoginBTN>
      <Link to="/login/signUp">
      <SignUpBTN >회원가입</SignUpBTN>
      </Link>
    </LoginSignUpBox>
  </StyledForm>
);

export default LoginForm;


const StyledForm = styled.form`
  margin-top: 40px;
  height: 100%;
`;

const InputBox = styled.div`
  border: 2px solid rgba(187, 185, 185, 0.5);
  border-radius: 10px;
  width: 392px;
  height: 131px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  @media (max-width: 425px) {
    width: 323px;
    height: 100px;
  }
`;

const StyledLabel = styled.label`
  display: block;
  width: 100%;
  margin: 13px;
  @media (max-width: 425px) {
    margin: 10px;
  }
`;

const StyledInput = styled.input`
  border: none;
  padding: 5px;
  padding-left: 27px;
  font-size: 23px;
  font-weight: 500;
  box-sizing: border-box;
  outline: none;
  &::placeholder {
    opacity: 0.5;
  }
  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

const StyledDiv = styled.div`
  background-color: rgba(187, 185, 185, 0.5);
  width: 392px;
  padding: 1px;
  @media (max-width: 425px) {
    width: 323px;
  }
`;


const FunCBOx = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 425px) {
    font-size: 12.3px;
  }
`;

const AutoLogin = styled.div`
  padding-left: 2px;
  justify-content: center;
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
`;

const LoginSignUpBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
`;

const LoginBTN = styled.button`
  width: 392px;
  height: 69px;
  background-color: #99393a;
  border-radius: 10px;
  border: none;
  font-size: 25px;
  color: white;
  font-weight: bold;
  cursor: pointer;

  @media (max-width: 425px) {
    width: 323px;
    height: 50px;
    font-size: 16px;
  }
`;

const SignUpBTN = styled.button`
  margin-top: 5px;
  width: 392px;
  height: 69px;
  border-radius: 10px;
  border: 2px solid rgba(187, 185, 185, 0.5);
  background-color: white;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  color: black;

  @media (max-width: 425px) {
    width: 323px;
    height: 50px;
    font-size: 16px;
  }
`;