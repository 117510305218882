import React, { useState, useEffect, useContext, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../services/axiosConfig";
import face from "../../../src/assets/images/mypage/face.png";
import Dropdown from "./Dropdown";
import { SearchContext } from "./QnA"; // Import the context
import btn from "../../../src/assets/images/qna/그룹 4.png";
import {Helmet} from "react-helmet";
const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  margin-top: 18px;

  color: #bbbbbb;
  padding: 150px 0;

  @media screen and (min-width: 1280px) {
    font-size: 26px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;

    margin-top: 16px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 16px;

    margin-top: 14px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 95px;
  gap: 16px;
  flex-wrap: wrap;

  @media screen and (min-width: 1280px) {
    margin-top: 95px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 80px;
  }

  @media screen and (max-width: 1023px) {
    margin-top: 60px;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const BtnContainer = styled.div`
  display: flex;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Button1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 22px;
  background-color: #ffffff;
  color: #99393a;
  border: solid 2px #99393a;
  border-radius: 266px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
font-weight: 500;


  @media screen and (min-width: 1280px) {
    padding: 12px 22px;
    font-size: 20px;
height: 50px;
line-height: 19px;

  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 10px 20px;
    font-size: 19px;
    margin-right: 8px;
line-height: 18px;

  }

  @media screen and (max-width: 1023px) {
    padding: 8px 14px;
    height: 35px; 
    font-size: 15px;
    margin-right: 0;
    margin-bottom: 10px;
  font-weight: 400;
  border: solid 1.5px #99393a;
  line-height: 14px;

  }
`;

const Button2 = styled.button`
  padding: 12px 22px;
  font-size: 23px;
  background-color: #99393a;
  color: white;
  border: none;
  border-radius: 266px;
  cursor: pointer;
  font-weight: 500;


  @media screen and (min-width: 1280px) {
    padding: 12px 22px;
    font-size: 20px;
height: 50px;
line-height: 19px;
  }



  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 10px 20px;
    font-size: 19px;
line-height: 18px;

  }

  @media screen and (max-width: 1023px) {
    padding: 8px 18px;
    font-size: 19px;
line-height: 18px;

  }
`;

const QuestionsSection = styled.div`
  margin-top: 32px;

  @media screen and (min-width: 1280px) {
    margin-top: 32px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 28px;
  }

  @media screen and (max-width: 1023px) {
    margin-top: 24px;
  }
`;

const QuestionCard = styled.div`
  border-bottom: 2px solid #e0e0e0;
  padding: 54px 0px;
  margin-bottom: 16px;
  background-color: #fff;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 1280px) {
    padding: 54px 0px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 40px 0px;
  }

  @media screen and (max-width: 1023px) {
    padding: 30px 0px;
  }
`;

const Qbox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Qbox1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const QuestionHeader = styled.div`
  display: flex;
  align-items: center;


  @media screen and (min-width: 1280px) {

  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {

  }

  @media screen and (max-width: 1023px) {
 
  }
`;

const StatusBadge = styled.div<{ isAnswered: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isAnswered ? "#99393a" : "white")};
  color: ${(props) => (props.isAnswered ? "white" : "#99393a")};
  border: solid 2px #99393a;
  font-weight: 500;
  font-size: 19px;
  padding: 4px 8px;
  width: 110px;
  height: 34px;
  border-radius: 17px;
  margin-right: 10px;
  position: relative;

  @media screen and (min-width: 1280px) {
    font-size: 19px;
    width: 110px;
    height: 34px;
    padding: 4px 8px;
    line-height: 18px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 17px;
    width: 100px;
    height: 32px;
    padding: 4px 6px;
    line-height: 16px;

  }

  @media screen and (max-width: 1023px) {
    font-size: 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    line-height: 14px;


    &::after {
      /* content: ${(props) => (props.isAnswered ? '""' : "none")}; */
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 7px;
      border: ${(props) =>
        props.isAnswered ? "solid #ffffff" : "solid #99393a"};
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
      color: ${(props) => (props.isAnswered ? "white" : "#99393a")};
    }
  }
`;

const QuestionTitle = styled.div`
cursor: pointer;
  font-size: 23px;
  
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  white-space: nowrap; /* 텍스트를 한 줄로 유지 */
  text-overflow: ellipsis; /* 말줄임표(...) 적용 */
  font-weight: 600;

  line-height: 25pt;

  @media screen and (min-width: 1280px) {
    font-size: 23px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 21px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 19px;
  }
`;


const QuestionContentWrapper = styled.div`
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 1280px) {
    font-size: 17px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 15px;
    -webkit-line-clamp: 3;
  }

`;

const QuestionContent = styled.pre`
  margin: 18px 0;
  font-size: 19px;
  line-height: 1.5; /* 줄 높이 설정 */
font-weight: 500;
  @media screen and (max-width: 1280px) {
    font-size: 17px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }
`;

const QuestionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;

  @media screen and (min-width: 1280px) {
    margin-top: 14px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 12px;
  }

  @media screen and (max-width: 1023px) {
    margin-top: 10px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UserIcon = styled.img`
  width: 23px;
  height: 23px;
  color: grey;
  margin-right: 8px;
  border-radius: 50%;

  @media screen and (min-width: 1280px) {
    width: 23px;
    height: 23px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 1023px) {
    width: 18px;
    height: 18px;
  }
`;

const UserName = styled.div`
  margin-right: 23px;
  font-weight: 600;
  color: #333333;
  font-size: 16px;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
    margin-right: 23px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
    margin-right: 20px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 14px;
    margin-right: 18px;
  font-weight: 500;

  }
`;

const TimeInfo = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #333333;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 14px;
  font-weight: 500;

  }
`;

const Keywords = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  position: relative;
  max-height: 68px; /* 키워드 컨테이너의 최대 높이를 설정 */

  @media screen and (min-width: 1280px) {
    /* margin-top: 15px; */
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
  }

  @media screen and (max-width: 1023px) {
    margin-top: 12px;
  }
`;

const Keyword = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #777777;
  font-size: 19px;
  padding: 4px 20px;
  margin-right: 10px;
  margin-top: 5px;
  /* min-width: 110px; */
  height: 34px;
  border-radius: 17px;
  border: solid 1.5px #cccccc;
  white-space: nowrap;
  font-weight: 400;
  
  @media screen and (min-width: 1280px) {
    font-size: 19px;
    line-height: 18px;
    /* min-width: 110px; */
    height: 34px;
    /* padding: 4px 12px; */
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 18px;
    line-height: 17px;

    /* min-width: 100px; */
    height: 32px;
    /* padding: 4px 10px; */
  }

  @media screen and (max-width: 1023px) {
    font-size: 12px;
    line-height: 11px;

    /* min-width: 60px; */
    height: 28px;
    /* padding: 5px 10px; */
  }
`;

const HashSpan = styled.span`
  font-size: 16px;
  margin-right: 1px;
  color:#999999;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
`;

const QuestionImage = styled.div`
  width: 142px;
  height: 142px;
  border-radius: 10px;
  margin-left: 16px;
  object-fit: contain; /* 이미지 전체를 보여주기 위해 contain으로 변경 */

  image-rendering: -webkit-optimize-contrast; /* 크롬, 사파리 */
  image-rendering: crisp-edges; /* 파이어폭스 */
  image-rendering: pixelated; /* 크롬, 사파리, 파이어폭스 */

  @media screen and (min-width: 1280px) {
    width: 142px;
    height: 142px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 120px;
    height: 120px;
  }

  @media screen and (max-width: 1023px) {
    width: 60px;
    height: 60px;
  }
`;

const Pcc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 70px;
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 400px; /* 숫자 10개가 들어갈 정도의 너비를 고정 */
  justify-content: space-between;

  @media screen and (min-width: 1280px) {
    width: 400px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 350px;
  }

  @media screen and (max-width: 1023px) {
    width: 300px;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const PageButton = styled.button<{ selected?: boolean }>`
  width: 30px; /* 버튼의 너비를 고정하여 간격을 일정하게 */
  padding: 8px 0;
  border: 0px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  color: ${(props) => (props.selected ? "#99393a" : "#9BA2AE")};
  font-size: 18px;
  font-weight: bold;

  &:hover {
    background-color: #f0f0f0;
  }

  @media screen and (min-width: 1280px) {
    width: 30px;
    font-size: 18px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 28px;
    font-size: 17px;
  }

  @media screen and (max-width: 1023px) {
    width: 26px;
    font-size: 16px;
  }
`;

const ArrowButton = styled.button<{ disabled?: boolean }>`
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.disabled ? "transparent" : "inherit")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0 : 1)};

  @media screen and (min-width: 1280px) {
    font-size: 18px;
    padding: 8px 12px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 17px;
    padding: 8px 10px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 16px;
    padding: 8px 8px;
  }
`;

const options = [
  { id: 1, label: "추천순" },
  { id: 2, label: "최신순" },
];

const FloatingButton = styled.img<{ show: boolean }>`
  display: none;

  @media screen and (max-width: 1023px) {
    display: ${(props) => (props.show ? "none" : "flex")};
    /* display: block; */
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 1000;
  }
`;

const ModalBackground = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

// 모달 컨테이너
const ModalContainer = styled.div<{ show: boolean }>`
  position: fixed;
  /* position: absolute; */
  bottom: 0px;
  background-color: white;
  z-index: 2000;
  border-radius: 10px 10px 0 0;
  width: 100%;

  animation: ${(props) => (props.show ? slideUp : slideDown)} 0.3s forwards;
`;

// 모달 버튼
const ModalButton = styled.button`
  width: 100%;
  padding: 21px;
  background-color: transparent;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
`;

const Guide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid #e0e0e0;
  padding: 44px 20px;
  border: none;

  border-bottom: 1px solid #000000;

  width: 100%;
  height: 275px;
`;

const Div1 = styled.div`
  font-size: 26px;

  color: #333333;
`;

const Div2 = styled.div`
  font-size: 14px;
  color: #555555;
`;

const Home: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [pageRange, setPageRange] = useState([1, 10]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [questions, setQuestions] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const location = useLocation();
  const navigate = useNavigate();

  // 쿼리 스트링에서 현재 페이지 번호 읽기
  const query = new URLSearchParams(location.search);
  const savedPage = query.get("page");
  const [currentPage, setCurrentPage] = useState<number>(
    savedPage ? parseInt(savedPage, 10) : 1
  );

  const handleFloatingButtonClick = () => {
    setShowModal(true);
  };

  const handleModalOutsideClick = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShowModal(false);
    }
  };

  const handleModalButtonClick = () => {
    navigate("/qnaform");
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleModalOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleModalOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleModalOutsideClick);
    };
  }, [showModal]);

  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error("useContext must be used within a SearchContext.Provider");
  }

  const { searchTerm } = searchContext;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sortBy =
          selectedOption.label === "최신순" ? "createdDate" : "mostRecommend";
        const requestParams = {
          searchKeyword: searchTerm,
          page: currentPage,
          sortBy: sortBy,
        };

        const response = await axiosInstance.get("/qna/list", {
          params: requestParams,
        });

        // console.log(response);
        setQuestions(response.data.content);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTerm, currentPage, selectedOption]);

  const handleOptionSelect = (option: (typeof options)[0]) => {
    setSelectedOption(option);
    setCurrentPage(1); // 드롭다운 메뉴 변경 시 1페이지로 이동
    navigate(`?page=1`, { replace: true }); // URL 쿼리 스트링도 1페이지로 업데이트
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    // 페이지가 변경될 때 쿼리 스트링 업데이트
    navigate(`?page=${page}`, { replace: true });
  };

  const handlePreviousPageRange = () => {
    if (pageRange[0] > 1) {
      setPageRange([pageRange[0] - 10, pageRange[1] - 10]);
    }
  };

  const handleNextPageRange = () => {
    if (pageRange[1] < totalPages) {
      setPageRange([pageRange[0] + 10, pageRange[1] + 10]);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const contentRef = useRef<HTMLPreElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
      const lines = element.clientHeight / lineHeight;
      if (lines > 3) {
        setIsTruncated(true);
      }
    }
  }, []);

  return (
    <>
        <Helmet>
          <title>Houber - QnA</title>
          <meta name="robots" content="index,follow"/>
          <meta name="description" content="일본 부동산에 대해 Houber에 편하게 물어보세요"/>
          <meta name="keywords" content="qna, QnA, 일본 부동산, 부동산, 질문, 일본 워홀, 워킹홀리데이"/>
          <meta property="og:title" content="Houber가 답해주는 QnA"/>
          <meta property="og:description" content="일본 부동산에 대해 Houber에 편하게 물어보세요"/>
        </Helmet>
      {isMobile ? (
        <>
          <Controls>
            <SelectWrapper>
              <Dropdown
                selectedOption={selectedOption}
                onOptionSelect={handleOptionSelect}
              />
            </SelectWrapper>
            <BtnContainer>
              <Button1 onClick={() => navigate(`/qna/myqna`)}>
                내 질문 확인
              </Button1>
              <FloatingButton
                show={showModal}
                src={btn}
                alt="질문하기"
                onClick={handleFloatingButtonClick}
              />
            </BtnContainer>
          </Controls>
          <QuestionsSection>
            {questions.length === 0 ? (
              <Subtitle>아직 등록된 글이 없습니다</Subtitle>
            ) : (
              questions.map((question, index) => (
                <QuestionCard key={index}>
                  <Qbox1>
                    <Qbox>
                      <QuestionHeader>
                        <StatusBadge isAnswered={question.answerStatus}>
                          {/* {question.answerStatus ? "답변완료" : "미답변"} */}
                        </StatusBadge>
                        <QuestionTitle
                          onClick={() =>
                            navigate(`/qna/detail/${question.questionId}`)
                          }
                        >{`Q. ${question.title}`}</QuestionTitle>
                      </QuestionHeader>

                      <QuestionFooter>
                        <UserInfo>
                          <UserIcon
                            src={question?.userProfileImage || face}
                            alt="avatar"
                          />
                          <UserName>{question.userNickName}</UserName>
                          <TimeInfo>{question.createAt}</TimeInfo>
                        </UserInfo>
                      </QuestionFooter>
                    </Qbox>

                    {question.thumbnail && (
                      <QuestionImage
                        as="img"
                        src={question.thumbnail}
                        alt="대표 이미지"
                      />
                    )}
                  </Qbox1>

                  <Keywords>
                    {question.keywords.map((keyword: string, index: number) => (
                      <Keyword key={index}>
                        <HashSpan>#</HashSpan>
                        {keyword}
                      </Keyword>
                    ))}
                  </Keywords>
                </QuestionCard>
              ))
            )}
          </QuestionsSection>
        </>
      ) : (
        <>
          <Controls>
            <SelectWrapper>
              <Dropdown
                selectedOption={selectedOption}
                onOptionSelect={handleOptionSelect}
              />
            </SelectWrapper>
            <BtnContainer>
              <Button1 onClick={() => navigate(`/qna/myqna`)}>
                내 질문 확인하기
              </Button1>
              <Button2 onClick={() => navigate(`/qnaform`)}>질문하기</Button2>
            </BtnContainer>
          </Controls>
          <QuestionsSection>
            {questions.length === 0 ? (
              <Subtitle>아직 등록된 글이 없습니다</Subtitle>
            ) : (
              questions.map((question, index) => (
                <QuestionCard key={index}>
                  <Qbox1>
                    <Qbox>
                      <QuestionHeader>
                        <StatusBadge isAnswered={question.answerStatus}>
                          {question.answerStatus ? "답변완료" : "미답변"}
                        </StatusBadge>
                        <QuestionTitle
                          onClick={() =>
                            navigate(`/qna/detail/${question.questionId}`)
                          }
                        >{`Q. ${question.title}`}</QuestionTitle>
                      </QuestionHeader>
                      <QuestionContentWrapper
                        onClick={() =>
                          navigate(`/qna/detail/${question.questionId}`)
                        }
                      >
                        <QuestionContent>{question.content}</QuestionContent>

                      </QuestionContentWrapper>
                      <QuestionFooter>
                        <UserInfo>
                          <UserIcon
                            src={question?.userProfileImage || face}
                            alt="avatar"
                          />

                          <UserName>{question.userNickName}</UserName>
                          <TimeInfo>{question.createAt}</TimeInfo>
                        </UserInfo>
                      </QuestionFooter>
                    </Qbox>

                    {question.thumbnail && (
                      <QuestionImage
                        as="img"
                        src={question.thumbnail}
                        alt="대표 이미지"
                      />
                    )}
                  </Qbox1>

                  <Keywords>
                    {question.keywords.map((keyword: string, index: number) => (
                      <Keyword key={index}>
                        <HashSpan>#</HashSpan>
                        {keyword}
                      </Keyword>
                    ))}
                  </Keywords>
                </QuestionCard>
              ))
            )}
          </QuestionsSection>
        </>
      )}
      <>
        {questions.length > 0 && (
          <Pcc>
            <PaginationContainer>
              <ArrowButton
                onClick={handlePreviousPageRange}
                disabled={pageRange[0] === 1}
              >
                &lt;
              </ArrowButton>
              <Pagination>
                {Array.from(
                  { length: Math.min(10, totalPages - pageRange[0] + 1) },
                  (_, index) => (
                    <PageButton
                      key={index}
                      selected={currentPage === pageRange[0] + index}
                      onClick={() => handlePageChange(pageRange[0] + index)}
                    >
                      {pageRange[0] + index}
                    </PageButton>
                  )
                )}
              </Pagination>
              <ArrowButton
                onClick={handleNextPageRange}
                disabled={pageRange[1] >= totalPages}
              >
                &gt;
              </ArrowButton>
            </PaginationContainer>
          </Pcc>
        )}
      </>

      <ModalBackground show={showModal}>
        <ModalContainer ref={modalRef} show={showModal}>
          {/* <ModalContent> */}
          <Guide>
            <Div1>Q&A 글 작성 가이드</Div1>
            <Div2>
              ⦁ 참고가 되는 사진을 올려주시면 더 좋은 답변을 얻을 수 있습니다
            </Div2>
            <Div2>
              ⦁ 글 작성시 키워드 3개는 필수 입력 항목입니다 (최대 8개까지 입력
              가능)
            </Div2>
            <Div2>
              ⦁ 일본 정보 및 부동산과 관련없는 질문은 삭제될 수 있습니다
            </Div2>
            <Div2>
              ⦁ 작성하신 글은 비슷한 어려움을 겪는 고객분들을 위해 삭제할 수
              없습니다
            </Div2>
          </Guide>
          {/* </ModalContent> */}
          <ModalButton onClick={handleModalButtonClick}>올리기</ModalButton>
        </ModalContainer>
      </ModalBackground>
    </>
  );
};

export default Home;
