// import styled from "styled-components";
import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import HouberMansionItem from "./HouberMansionItem";
import { houberMansionItemType } from "../HouberMansion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HouberMansionNavigation from "./HouberNavigation";
import { getMaxPage } from "../../../components/Navigation";

function HouberMansionContents({
  houberMansionData,
}: {
  houberMansionData?: houberMansionItemType[];
}) {
  const navigate = useNavigate();
  const { isPC, isLaptop, isMobile } = useDeviceSize();
  const [contentsPageState, setContentsPageState] = useState(0);

  const [currentPageMansions, setCurrentPageMansions] = useState<
    houberMansionItemType[]
  >([]);
  const [mainContentsState, setMainContentsState] = useState<{
    thumbnailUrl: string;
    roomId: number;
  }>();
  const [fadeState, setFadeState] = useState(true);


  useEffect(() => {
    if (houberMansionData && houberMansionData.length > 0) {
      setMainContentsState({
        thumbnailUrl: houberMansionData[0].thumbnailUrl,
        roomId: houberMansionData[0].id,
      });
    }
  }, [houberMansionData]);
  ////////////////
  useEffect(() => {
    if (houberMansionData && houberMansionData.length > 0) {
      const maxIndex = houberMansionData.length - 1;
      const slideInterval = setInterval(() => {
        setFadeState(false);
        setTimeout(()=> {
          setMainContentsState((prevState) => {
            // 현재 보여지고 있는 이미지의 인덱스를 찾음
            const currentIndex = houberMansionData.findIndex(
                (item) => item.id === prevState?.roomId
            );
            // 다음 인덱스 계산
            const nextIndex = currentIndex < maxIndex ? currentIndex + 1 : 0;

            // mainContentsState 업데이트
            return {
              thumbnailUrl: houberMansionData[nextIndex].thumbnailUrl,
              roomId: houberMansionData[nextIndex].id,
            };
          });
          setFadeState(true);
        },300);
      }, 10000); // 10초

      return () => clearInterval(slideInterval);
    }
  }, [houberMansionData]);
//////////////////////
  useEffect(() => {
    if (houberMansionData) {
      const itemsPerPage = 3;
      const startIndex = contentsPageState * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setCurrentPageMansions(houberMansionData.slice(startIndex, endIndex));
    }
  }, [contentsPageState, houberMansionData, isMobile]);

  const getMainContentsState = (newItemState: {
    thumbnailUrl: string;
    roomId: number;
  }) => {
    setMainContentsState(newItemState);
  };

  const onClickMainItem = () => {
    if (mainContentsState) {
      navigate(`/detail?roomId=${mainContentsState?.roomId}`);
    }
  };
  return (
    <HouberMansionContentsWrapper>
      <HouberMansionMainItem onClick={onClickMainItem}>
        {mainContentsState && (
          <Styledimage
              src={mainContentsState?.thumbnailUrl}
              fadeState ={fadeState}
          />
        )}
      </HouberMansionMainItem>
      <ContentsRight>
        {!houberMansionData || houberMansionData.length < 1 ? (
          <NoItemArea>
            <div>조건에 해당하는 매물이</div> <div>없습니다.</div>
          </NoItemArea>
        ) : (
          <>
            <HouberMansionNavigation
              onClickNext={() => {
                setContentsPageState(contentsPageState + 1);
              }}
              onClickPrev={() => {
                setContentsPageState(contentsPageState - 1);
              }}
              page={contentsPageState}
              maxPage={getMaxPage(3, houberMansionData?.length)}
              translateX={isPC ? 80 : isLaptop ? 62 : 80}
            ></HouberMansionNavigation>
            {currentPageMansions &&
              currentPageMansions.map((item, index) => (
                <HouberMansionItem
                  key={index}
                  roomInfo={item}
                  getMainContentsState={getMainContentsState}
                />
              ))}
          </>
        )}
      </ContentsRight>
    </HouberMansionContentsWrapper>
  );
}

export default HouberMansionContents;

const HouberMansionContentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (min-width: 1280px) {
    height: 864.5px;
    padding-top: 51px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 681.5px;
    padding-top: 35px;
  }
  @media screen and (max-width: 1023px) {
    height: 1000px;
    padding-top: 25px;
  }
`;

const HouberMansionMainItem = styled.div`
  background-color: #eaebef;
  border-radius: 0 40px 40px 0;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    width: 728.5px;
    height: 657px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 588px;
    height: 545px;
  }
  @media screen and (max-width: 1023px) {
    width: 404px;
    height: 376px;
  }
`;

const Styledimage = styled.img<{ fadeState: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 0 40px 40px 0;
  opacity: ${(props) => (props.fadeState ? 1 : 0)}; // 페이드 인/아웃을 위한 opacity 설정
  transition: opacity 0.5s ease-in-out; // 페이드 인/아웃 효과
`;
const ContentsRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    height: 666px;
    margin-left: 37.5px;
    padding: 20px 0;
    gap: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 590px;
    margin-left: 27px;
    padding: 35px 0;
    gap: 18px;
  }
  @media screen and (max-width: 1023px) {
    height: 554px;
    width: 100%;
    margin-left: 8.5px;
    padding: 24px 0;
    gap: 2px;
  }
`;

const NoItemArea = styled.div`
  display: flex;

  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #837474;
  @media screen and (min-width: 1280px) {
    width: 448.5px;
    font-size: 27px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 354px;
    font-size: 24px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    font-size: 18px;
  }
`;
