// import styled from "styled-components";
import styled from "styled-components";
import PageHeader from "../../components/PageHeader";
import PopularMansionCategory from "./components/PopularMansionCategory";
import { useEffect, useState } from "react";
import axiosInstance from "../../services/axiosConfig";
import houseIcon from "../../assets/images/popularMansion/house.png";
import usePopularMansionData from "../../recoil/mainPge/popularMansionDataStateAtom";
import useCheckItems from "../../store/modal/checkItems";
import {Helmet} from "react-helmet";

type popularMansionResponseType = {
  data: popularMansionDataType;
};

export type popularMansionDataType = {
  bestRooms: {
    roomType: string;
    rooms?: popularMansionItemType[];
  }[];
};

export type popularMansionItemType = {
  isScrapbook: boolean;
  name: string;
  recommendPoints: string;
  id: number;
  roomNum: number;
  thumbnailUrl: string;
};

function PopularMansion() {
  const { popularMansionDataState, setPopularMansionDataState } =
    usePopularMansionData();

  const [selectedDistrictState, setSelectedDistrictState] =
    useState<number[]>();

  const { items } = useCheckItems();

  useEffect(() => {
    const fetchPopularMansion = async () => {
      const response: popularMansionResponseType = await axiosInstance.get(
        "/normal/home/detailBest",
        {
          params: {
            ids: selectedDistrictState && selectedDistrictState?.join(","),
          },
        }
      );

      setPopularMansionDataState(response.data);

    };

    fetchPopularMansion();
  }, [selectedDistrictState]);

  useEffect(() => {
    if (items.length > 0) {
      const ids = items.map((item) => item.id);
      setSelectedDistrictState(ids);
    }
  }, [items]);

  return (
      <>
        {/* React Helmet 적용 */}
        <Helmet>
          <title>Houber - latestMansion</title>
          <meta name="robots" content="index,follow"/>
          <meta name="description" content="매물 최신 순 정렬"/>
          <meta name="keywords" content="일본 부동산, 일본, 워홀, 워킹홀리데이, 유학, 맨션, 일본 워홀, 부동산"/>
          <meta property="og:title" content="Houber"/>
          <meta property="og:description" content="최신에 추가된 Houber 매물입니다."/>
        </Helmet>
    <PopularMansionWrapper>
      {/*<PageHeader src={bestIcon} title="베스트 인기 매물" selectArea />*/}
      <PageHeader src={houseIcon} title="최신 등록 매물" selectArea />
      <PopularMansionMargin />
      <PopularMansionContents>
        <PopularMansionCategory
          title="1K"
          explain="(1 Kitchen)"
          maxItem={1200}
          rooms={
            popularMansionDataState?.bestRooms[0] &&
            popularMansionDataState?.bestRooms[0].rooms
          }
        />
        <PopularMansionCategory
          title="1DK"
          explain="(1 Dining room&Kitchen)"
          rooms={
            popularMansionDataState?.bestRooms[1] &&
            popularMansionDataState?.bestRooms[1].rooms
          }
        />
        <PopularMansionCategory
          title="1LDK"
          explain="(1 Living&Dining room&Kitchen)"
          rooms={
            popularMansionDataState?.bestRooms[2] &&
            popularMansionDataState?.bestRooms[2].rooms
          }
        />
        <PopularMansionCategory
          title="2LDK"
          explain="(2 Living&Dining room&Kitchen)"
          rooms={
            popularMansionDataState?.bestRooms[3] &&
            popularMansionDataState?.bestRooms[3].rooms
          }
        />
        <PopularMansionCategory
          title="기타점포"
          rooms={
            popularMansionDataState?.bestRooms[4] &&
            popularMansionDataState?.bestRooms[4].rooms
          }
        />
      </PopularMansionContents>
    </PopularMansionWrapper>
        </>
  );
}

export default PopularMansion;

const PopularMansionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PopularMansionMargin = styled.div`
  width: 100%;
  height: 0;
  @media screen and (min-width: 1280px) {
    margin-bottom: 51px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 54px;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 26px;
  }
`;

const PopularMansionContents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 1280px) {
    gap: 58px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 67px;
  }
  @media screen and (max-width: 1023px) {
    gap: 35px;
  }
`;
