import React, { useState } from "react";
import { styled } from "styled-components";
import useCheckItems from "../../../store/modal/checkItems";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import useChoiceAreaModalState from "../../../store/modal/choiceArea";

const Preview = ({ ...props }) => {
  const { returnItems, setItems } = useCheckItems();
  const { close } = useChoiceAreaModalState();
  const [topFoldState, setTopFoldState] = useState<any>({});
  const [parentFoldState, setParentFoldState] = useState<any>({});

  const groupedData = returnItems.reduce((acc, item) => {
    (acc[item.top] = acc[item.top] || []).push(item);
    return acc;
  }, {});
  Object.keys(groupedData).forEach((top) => {
    groupedData[top] = groupedData[top].reduce((acc: any, item: any) => {
      (acc[item.parent] = acc[item.parent] || []).push(item);
      return acc;
    }, {});
  });

  const toggleParentFold = (parent: string) => {
    setParentFoldState({
      ...parentFoldState,
      [parent]: !parentFoldState[parent],
    });
  };
  const toggleTopFold = (parent: string) => {
    setTopFoldState({
      ...topFoldState,
      [parent]: !topFoldState[parent],
    });
  };

  const renderTopItems = () =>
    Object.keys(groupedData).map((top) => (
      <Inner key={top}>
        <Top>
          <span>{top}</span>
          {!topFoldState[top] ? (
            <FaAngleRight onClick={() => toggleTopFold(top)} />
          ) : (
            <FaAngleDown onClick={() => toggleTopFold(top)} />
          )}
        </Top>
        {/* parent에 따라 하위 컴포넌트를 렌더링 */}
        {topFoldState[top] && renderParentItems(groupedData[top])}
      </Inner>
    ));

  const renderParentItems = (parentData: any) =>
    Object.keys(parentData).map((parent) => (
      <Inner key={parent}>
        <Parent>
          <span>{parent}</span>
          {!parentFoldState[parent] ? (
            <FaAngleRight onClick={() => toggleParentFold(parent)} />
          ) : (
            <FaAngleDown onClick={() => toggleParentFold(parent)} />
          )}
        </Parent>
        {/* 하위 데이터 (Leaf 컴포넌트) 렌더링 */}
        {parentFoldState[parent] && (
          <ul>
            {parentData[parent].map((item: any) => (
              <Leaf key={item.id}>
                {"- "}
                {item.name}
              </Leaf>
            ))}
          </ul>
        )}
      </Inner>
    ));

  return (
    <>
      <Container>
        {returnItems.length ? (
          <InnerLayout>{renderTopItems()}</InnerLayout>
        ) : (
          <DefaultSpan>
            {props.dataType === "location" ? "지역" : "노선"}을 선택해 주세요.
          </DefaultSpan>
        )}
      </Container>
      <SubmitButton
        disabled={!returnItems.length}
        onClick={() => {
          setItems(
            returnItems.map((item) => {
              if (props.dataType === "location") {
                return {
                  id: item.id,
                  name: item.top + " " + item.name,
                };
              } else {
                return {
                  id: item.id,
                  name: item.top + " " + item.parent + " " + item.name,
                };
              }
            })
          );
          close();
        }}
      >
        {props.dataType === "location" ? "지역선택" : "노선선택"}
      </SubmitButton>
    </>
  );
};

export default Preview;

const Container = styled.div`
  font-family: "Noto Sans KR";
  border: 1px solid #dedede;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 88%;
  padding: 20px 10px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
  @media screen and (max-width: 1023px) {
    height: 200px;
  }
`;
const DefaultSpan = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  color: #555555;
  font-weight: 500;
  opacity: 0.5;
  @media screen and (min-width: 1280px) {
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
`;

const InnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 600;
  width: 100%;
  color: #000000a0;
  padding: 15px 20px;
  background-color: rgb(153, 57, 58, 0.7);
  border-radius: 10px;
`;

const Parent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  color: #000000a0;
  padding: 20px 20px 0 27px;
`;
const Leaf = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  color: #000000a0;
  padding: 4px 0px 4px 34px;
`;

const SubmitButton = styled.button`
  font-family: "Noto Sans KR";
  width: 100%;
  height: 60px;
  border: transparent;
  background-color: #99393a;
  border-radius: 40px;
  color: white;
  font-weight: 500;
  font-size: 25px;
  &:disabled {
    background-color: #e6e6e6;
    color: gray;
  }
`;
