import React from "react";
import styled from "styled-components";

interface PasswordChangeFormProps {
  newPW: string;
  checkNewPW: string;
  onNewPWChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckNewPWChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  passwordValid: boolean;
  passwordMismatch: boolean;
}

const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({
  newPW,
  checkNewPW,
  onNewPWChange,
  onCheckNewPWChange,
  passwordValid,
  passwordMismatch,
}) => {
  return (
    <>
 
      <InputField
        type="password"
        value={newPW}
        onChange={onNewPWChange}
        placeholder="비밀번호"
        minLength={8}
      />
      <InputField
        type="password"
        value={checkNewPW}
        onChange={onCheckNewPWChange}
        placeholder="비밀번호 확인"
        minLength={8}
        disabled={!passwordValid || newPW === ""}
      />
      {!passwordValid && (
        <ErrorMessage>유효하지 않은 비밀번호입니다.</ErrorMessage>
      )}
      {passwordMismatch && newPW !== "" && (
        <ErrorMessage>동일한 비밀번호를 입력해주세요.</ErrorMessage>
      )}
     
    </>
  );
};

export default PasswordChangeForm;

const InputField = styled.input`
  border-radius: 10px;
  width: auto;
  margin: 5px 0;
  font-size: 23px;
  border: 1px solid #ccc;
  height: 60px;
  outline: none;
  padding-left: 20px;
  font-weight: 500;
  font-family: "Noto Sans KR";
    &::placeholder {
        color: #bbbbbb;
        opacity: 1;
    }
  @media (max-width: 500px) {
    font-size: 12px;
    height: 34px;
    padding-left: 20px;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;

  @media (max-width: 500px) {
    font-size: 11px;
  }
`;



const PwDiv = styled.div`
  @media (max-width: 500px) {
    font-size: 11px;
  }
`;
